import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React, { FC } from 'react'
import { AdminTheme } from '../../../theme/theme'
import { Flex } from '../../helpers/Flex'
import { getSocialLink } from '../../helpers/get-social-icon'
import { SocialLink } from '../../../common/types/social-link'

const StyledSocialLink = withTheme(
    styled.a(({ theme }: { theme: AdminTheme }) => ({
        width: '32px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.InteractivePrimary,
        backgroundColor: theme.InteractiveSecondaryHover,
        borderRadius: '50%',
        '&:hover, &:active': {
            color: theme.InteractivePrimaryHover,
            transform: 'scale(1.125)',
            transition: 'transform ease 0.1s',
        },
    }))
)

export const SocialLinks: FC<{ socialLinks: SocialLink[] | undefined; className?: string }> = ({
    socialLinks,
    className,
}) => (
    <Flex gap={1.5} className={className} flexWrap="wrap" justifyContent="flex-start">
        {socialLinks?.map((link, i) => (
            <React.Fragment key={i}>
                {/* eslint-disable-next-line react/jsx-no-target-blank*/}
                <StyledSocialLink href={link.link} target="_blank">
                    {getSocialLink(link.type)}
                </StyledSocialLink>
            </React.Fragment>
        ))}
    </Flex>
)
