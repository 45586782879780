import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useBusiness } from '../../../hooks/use-business'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Flex } from '../../helpers/Flex'
import { AdminTheme } from '../../../theme/theme'
import { useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useMatchMedia } from '../../../hooks/use-match-media'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { copyToClipboard } from '../../../services/copy-to-clipboard'
import { useToast } from '../../../hooks/use-toast'
import { StarterBannerTimer } from './StarterBannerTimer'
import moment from 'moment'
import { useCurrentMessage } from '../../../store/getting-started/hooks'
import { SETUP_IN_PROGRESS_KEY } from '../../../pages/admin/wizard/steps/Step1'
import { classNames } from '../../../services/class-names'
import { CouponButtonNavLink } from './CouponButton'
import { asClickable } from '../../helpers/as-clickable'
import { BillingCycle } from '../../../common/types/enums'

export const StarterBannerBreakpointMedia = '(min-width: 1280px)'
export const StarterBannerBreakpoint = `@media${StarterBannerBreakpointMedia}`

const BannerTitle = withTheme(
    styled.h1(({ theme }: { theme: AdminTheme }) => ({
        display: 'inline-flex',
        gap: theme.Spacing(1),
        ...theme._HeadingSmall,
        flexWrap: 'wrap',
    }))
)

const StarterCouponBannerContainer = withTheme(
    styled.section(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        padding: `${theme.Spacing(1.5)} ${theme.Spacing(2.5)}`,
        backgroundColor: theme.InteractiveProHover,
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.Spacing(2),
    }))
)

const Discount = styled.span(() => ({
    color: 'hsla(307, 84%, 50%, 1)',
}))

const CopyToClipboard = asClickable(
    withTheme(
        styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
            padding: `0 ${theme.Spacing(1)}`,
            flexDirection: 'column',
            flexShrink: 0,
            cursor: 'pointer',
        }))
    )
)

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)(() => ({
    fontSize: 12,
    [StarterBannerBreakpoint]: {
        fontSize: 16,
    },
}))

export const StarterCouponBanner: FC = () => {
    const business = useBusiness()!
    const coupon = useMemo<{ name: string; billingCycle: BillingCycle }>(
        () =>
            business.id % 2 === 0
                ? { name: 'STARTER1', billingCycle: 'monthly' }
                : { name: 'STARTER2', billingCycle: 'monthly' },
        [business.id]
    )
    const couponLink = useMemo(() => {
        const query = new URLSearchParams()
        query.append('coupon', coupon.name)
        query.append('billingCycle', coupon.billingCycle)
        return `/admin/subscription/checkout/trial?${query.toString()}`
    }, [coupon.billingCycle, coupon.name])
    const [show, setShow] = useState(false)
    const currentMessage = useCurrentMessage()
    const setupInProgress = useMemo(() => {
        return localStorage.getItem(SETUP_IN_PROGRESS_KEY)
        // Rechecks local storage when current message changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMessage])

    useEffect(() => {
        if (
            currentMessage?.messageId !== 'trial-popup' &&
            !setupInProgress &&
            business.subscriptionTier === 'free' &&
            business.proTrialAvailable &&
            moment.utc(business.createdAt).isAfter(moment.utc().subtract(1, 'day'))
        ) {
            setShow(true)
        }
    }, [
        business.createdAt,
        business.proTrialAvailable,
        business.subscriptionTier,
        currentMessage?.messageId,
        setupInProgress,
    ])

    const location = useLocation()
    const bigScreen = useMatchMedia(StarterBannerBreakpointMedia)
    const toast = useToast()
    const { t } = useTranslation('admin')

    const copyCouponName = useCallback(() => {
        copyToClipboard(coupon.name)
        toast.success(t('Coupon copied to clipboard.'), '', 3)
    }, [coupon.name, t, toast])

    return show ? (
        <StarterCouponBannerContainer>
            {bigScreen ? (
                <BannerTitle>
                    🤑{' '}
                    <Trans ns="admin">
                        Start your Minup Pro trial by{' '}
                        <StarterBannerTimer
                            createdAt={moment.utc(business.createdAt)}
                            onTimerEnd={() => setShow(false)}
                        />{' '}
                        and get <Discount>25% discount!</Discount>
                    </Trans>
                </BannerTitle>
            ) : (
                <StarterBannerTimer createdAt={moment.utc(business.createdAt)} onTimerEnd={() => setShow(false)} />
            )}
            {location.pathname.includes('checkout') ? (
                <CopyToClipboard onClick={copyCouponName}>
                    {bigScreen ? null : (
                        <Discount className="heading small">
                            <Trans ns="admin">25% OFF</Trans>
                        </Discount>
                    )}
                    <Flex gap={0.5}>
                        <Flex gap={0.5}>
                            <span className={classNames(bigScreen ? 'bodymedium' : 'caption semibold')}>
                                <Trans ns="admin">Coupon</Trans>:
                            </span>
                            <span className={classNames(bigScreen ? 'heading small' : 'caption semibold')}>
                                {coupon.name}
                            </span>
                        </Flex>
                        <StyledFontAwesomeIcon icon={faCopy} />
                    </Flex>
                </CopyToClipboard>
            ) : (
                <CouponButtonNavLink to={couponLink}>
                    {bigScreen ? <Trans ns="admin">ACTIVATE COUPON</Trans> : <Trans ns="admin">GET 25% OFF</Trans>}
                </CouponButtonNavLink>
            )}
        </StarterCouponBannerContainer>
    ) : null
}
