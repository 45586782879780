import { LanguageCode } from '../../i18n'
import { Action } from '../utils/create-action'
import { Business, FeatureFlags } from './types'

export enum AuthActionTypes {
    UPDATE_BUSINESS = '[auth/business] update',
    UPDATE_BUSINESS_TIMEZONE = '[auth/business] update timezone',
    UPDATE_BUSINESS_LANGUAGE = '[auth/business] update language',
    REMOVE_PROFILE_IMAGE = '[auth/business] remove profile image',
    REMOVE_COVER_IMAGE = '[auth/business] remove cover image',
    SET_LOADING = '[auth] set loading',
    SET_AUTHENTICATED = '[auth] set authenticated',
    SET_ACCESS_TOKEN = '[auth] set access token',
    AUTH_FINISHED = '[auth] auth finished',
    AUTH_RESET = '[auth] auth reset',
    SET_FEATURE_FLAGS = '[auth/business] set feature flags',
}

export type UpdateBusinessAction = Action<typeof AuthActionTypes.UPDATE_BUSINESS, Partial<Business>>
type UpdateBusinessTimezone = Action<typeof AuthActionTypes.UPDATE_BUSINESS_TIMEZONE, string>
type UpdateBusinessLanguage = Action<typeof AuthActionTypes.UPDATE_BUSINESS_LANGUAGE, LanguageCode>
type RemovProfileImage = Action<typeof AuthActionTypes.REMOVE_PROFILE_IMAGE, undefined>
type RemoveCoverImage = Action<typeof AuthActionTypes.REMOVE_COVER_IMAGE, undefined>
type SetLoading = Action<typeof AuthActionTypes.SET_LOADING, boolean>
type SetAccessToken = Action<typeof AuthActionTypes.SET_ACCESS_TOKEN, AuthResult | undefined>
type SetFeatureFlagsAction = Action<typeof AuthActionTypes.SET_FEATURE_FLAGS, FeatureFlags | undefined>

export type AuthAction =
    | UpdateBusinessAction
    | UpdateBusinessTimezone
    | UpdateBusinessLanguage
    | SetLoading
    | SetAccessToken
    | RemovProfileImage
    | RemoveCoverImage
    | SetFeatureFlagsAction
