import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PrimaryButton } from '../../../../components/ui-kit/button/primary'
import { InputGroup } from '../../../../components/ui-kit/comopnents/InputGroup'
import { useBusinessApi } from '../../../../hooks/apis/use-business-api'
import { AuthActionTypes } from '../../../../store/auth/actions'
import { createAction } from '../../../../store/utils/create-action'
import { WizardStepContent } from '../WizardStepContent'
import { WizardStepFooter } from '../WizardStepFooter'
import { Flex } from '../../../../components/helpers/Flex'
import { Trans, useTranslation } from 'react-i18next'
import {
    faAmericanSignLanguageInterpreting,
    faBriefcase,
    faCamera,
    faCar,
    faCut,
    faDragon,
    faDumbbell,
    faGraduationCap,
    faHeartbeat,
    faLifeRing,
    faShapes,
    faSpa,
    faSprayCan,
    faStore,
} from '@fortawesome/free-solid-svg-icons'
import { useBusiness, useSetBusiness } from '../../../../hooks/use-business'
import { useToast } from '../../../../hooks/use-toast'
import { captureException } from '../../../../appInsights'
import { Column } from '../../../../components/admin/layout/Column'
import { AutocompleteInput, AutocompleteItem } from '../../../../components/ui-kit/comopnents/AutocompleteInput'
import { Input } from '../../../../components/ui-kit/comopnents/Input'
import { Icon } from '../../../../components/ui-kit/comopnents/Icon'
import { LashArtist } from '../../../../icons/custom/LashArtist'

export function useServiceTypes() {
    const { t } = useTranslation('admin')
    return useMemo<AutocompleteItem[]>(() => {
        return [
            {
                label: t('Nail salon'),
                value: 'nail-salon',
                extra: {
                    icon: faAmericanSignLanguageInterpreting,
                },
            },
            {
                label: t('Consulting'),
                value: 'consulting',
                extra: {
                    icon: faBriefcase,
                },
            },
            {
                label: t('Beauty salon'),
                value: 'beauty-salon',
                extra: {
                    icon: faSprayCan,
                },
            },
            {
                label: t('Lash artist'),
                value: 'lash-artist',
                extra: {
                    icon: LashArtist,
                },
            },
            {
                label: t('Massage'),
                value: 'massage',
                extra: {
                    icon: faSpa,
                },
            },
            {
                label: t('Coaching'),
                value: 'coaching',
                extra: {
                    icon: faLifeRing,
                },
            },
            {
                label: t('Education'),
                value: 'education',
                extra: {
                    icon: faGraduationCap,
                },
            },
            {
                label: t('Barbershop'),
                value: 'barbershop',
                extra: {
                    icon: faCut,
                },
            },
            {
                label: t('Fitness'),
                value: 'fitness',
                extra: {
                    icon: faDumbbell,
                },
            },
            {
                label: t('Tattoo artist'),
                value: 'tattoo-artist',
                extra: {
                    icon: faDragon,
                },
            },
            {
                label: t('Photography'),
                value: 'photography',
                extra: {
                    icon: faCamera,
                },
            },
            {
                label: t('Healthcare'),
                value: 'healthcare',
                extra: {
                    icon: faHeartbeat,
                },
            },
            {
                label: t('Automotive'),
                value: 'automotive',
                extra: {
                    icon: faCar,
                },
            },
            {
                label: t('Store'),
                value: 'store',
                extra: {
                    icon: faStore,
                },
            },
            {
                label: t('Other'),
                value: 'other',
                extra: {
                    icon: faShapes,
                },
            },
        ]
    }, [t])
}

export const Step2: FunctionComponent = () => {
    const business = useBusiness()
    const businessApi = useBusinessApi()
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation('admin')
    const toast = useToast()
    const [serviceType, setServiceType] = useState('')
    const [industry, setIndustry] = useState('')
    const [loading, setLoading] = useState(false)
    const updateBusiness = useSetBusiness()

    const submit = useCallback(
        async (e) => {
            e.preventDefault()
            if (!business || !serviceType) {
                return
            }
            try {
                setLoading(true)
                const response = await businessApi.updateServiceType(serviceType, industry, true)
                dispatch(createAction(AuthActionTypes.AUTH_FINISHED, undefined))
                updateBusiness({
                    serviceType,
                    industry,
                    allowMultipleServiceBooking: response.data.allowMultipleServiceBooking,
                })
                setLoading(false)
                history.push('/admin/booking/overview/profile#register')
            } catch (e) {
                setLoading(false)
                toast.error(t('Something went wrong, please try again'))
                captureException(e)
            }
        },
        [business, serviceType, businessApi, industry, dispatch, updateBusiness, history, toast, t]
    )

    const options = useServiceTypes()

    const onChangeType = useCallback(
        (selected: AutocompleteItem) => {
            setServiceType(selected.value as string)
            if (serviceType === 'other') {
                setIndustry('')
            }
        },
        [serviceType]
    )

    return (
        <form onSubmit={submit}>
            <WizardStepContent id="wizard-step-1" className="pb0">
                <Column>
                    <h2>
                        <Trans ns="admin">What do you do?</Trans>
                    </h2>
                    <p>
                        <Trans ns="admin">
                            Choose the type of service that suits you the best so that we can provide a tailored support
                            experience for you.
                        </Trans>
                    </p>
                    <InputGroup>
                        <label htmlFor="typeOfService">
                            <Trans ns="admin">What type of service do you offer?</Trans>
                        </label>
                        <AutocompleteInput
                            id="typeOfService"
                            options={options}
                            placeholder={t('Select an option')}
                            value={serviceType}
                            onChange={onChangeType}
                            disableAutocomplete
                            renderItem={(item) => (
                                <Flex justifyContent="flex-start" gap={1}>
                                    {item.extra ? <Icon className="grey" icon={item.extra.icon} /> : null}
                                    {item.label}
                                </Flex>
                            )}
                        />
                    </InputGroup>
                    {serviceType === 'other' && (
                        <InputGroup>
                            <label htmlFor="industry">
                                <Trans ns="admin">What do you do?</Trans>
                            </label>
                            <Input
                                required
                                id="industry"
                                value={industry}
                                onChange={(e) => setIndustry(e.target.value)}
                                maxLength={255}
                            />
                        </InputGroup>
                    )}
                </Column>
            </WizardStepContent>
            <WizardStepFooter justifyContent="flex-end">
                <Flex grow={1}>
                    <PrimaryButton className="w100" tabIndex={2} disabled={loading}>
                        <Trans ns="admin">Let’s go</Trans>
                    </PrimaryButton>
                </Flex>
            </WizardStepFooter>
        </form>
    )
}
