import styled from '@emotion/styled'
import { withTheme } from '@emotion/react'
import { fromTheme } from '../../../theme/theme'
import { Flex } from '../../../components/helpers/Flex'

export const WizardStepFooter = withTheme(styled(Flex)`
    width: 100%;
    display: flex;
    padding: 24px 20px;

    }

    & > div:last-of-type {
        display: flex;
    }

    border-top: 1px solid ${fromTheme('Neutral_300')};

    ${fromTheme('BreakPoint')} {
        padding: 24px;
    }
`)
