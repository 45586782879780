import { faFire, faCheck, faTimes, faSpinner, faRocket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useMemo, useState, useCallback, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Column } from '../../../../components/admin/layout/Column'
import { AdminCurrency } from '../../../../components/helpers/Currency'
import { Flex } from '../../../../components/helpers/Flex'
import { LocalDate } from '../../../../components/helpers/LocalDate'
import { HorizontalDivider } from '../../../../components/ui-kit/admin/HorizontalDivider'
import { SegmentOption, SegmentedPicker } from '../../../../components/ui-kit/admin/SegmentedPicker'
import { LightButton } from '../../../../components/ui-kit/button/LightButton'
import { NeutralButton } from '../../../../components/ui-kit/button/neutral'
import { NeutralIconButton } from '../../../../components/ui-kit/button/NeutralIconButton'
import { TextButtonLink, TextButton } from '../../../../components/ui-kit/button/TextButton'
import { Icon } from '../../../../components/ui-kit/comopnents/Icon'
import { InputGroup } from '../../../../components/ui-kit/comopnents/InputGroup'
import { LoadingScreen } from '../../../../components/ui-kit/comopnents/LoadingScreen'
import { RequiredIndicator } from '../../../../components/ui-kit/comopnents/RequiredIndicator'
import { PaymentStartDto } from '../../../../hooks/apis/use-subscription-api'
import { useFormatCurrency } from '../../../../hooks/use-format-currency'
import { useMatchMedia } from '../../../../hooks/use-match-media'
import { Business } from '../../../../store/auth/types'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../../theme/theme'
import { SubscriptionBreakpoint, SubscriptionBreakpointMedia } from './SubscriptionCheckout'
import { Input } from '../../../../components/ui-kit/comopnents/Input'
import { classNames } from '../../../../services/class-names'
import { BillingCycle, SubscriptionTier } from '../../../../common/types/enums'

const SummaryContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.Spacing(3),
        marginTop: theme.Spacing(3),
        [SubscriptionBreakpoint]: {
            paddingLeft: theme.Spacing(4),
            marginTop: 0,
        },
    }))
)

const StyledIconContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '48px',
        fontSize: '32px',
        borderRadius: '50%',
        color: theme.Secondary3_900,
        backgroundColor: theme.Secondary3_100,
    }))
)

const StyledUnorderedList = withTheme(
    styled.ul(({ theme }: { theme: AdminTheme }) => ({
        margin: 0,
        paddingLeft: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.Spacing(1.5),
        listStyle: 'none',

        svg: {
            color: theme.Secondary3_500,
        },
    }))
)

const Coupon = withTheme(
    styled(Column)(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        padding: `${theme.Spacing(1.5)} ${theme.Spacing(2)}`,
        borderRadius: 8,
        backgroundColor: theme.BackgroundWarningSecondary,
        '.close-button': {
            position: 'absolute',
            right: theme.Spacing(2),
        },
    }))
)

const CouponIcon = withTheme(
    styled(Flex)(({ theme, size }: { theme: AdminTheme; size: number }) => ({
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: theme.BackgroundWarningPrimary,
        svg: {
            color: theme.BackgroundWarningSecondary,
        },
    }))
)

const InlineSpan = styled.span(() => ({
    display: 'inline-flex',
}))

const CouponInputContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        maxWidth: '100%',
        display: 'none',
        flexDirection: 'row',
        gap: theme.Spacing(2),
        input: {
            flex: '1 1 0',
            minWidth: 0,
        },
        '&.show': {
            display: 'flex',
        },
    }))
)

const FlexLabel = withTheme(
    styled.label(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.Spacing(1),

        input: {
            margin: 0,
            width: 16,
            height: 16,
        },
    }))
)

export const Summary: FC<{
    loading: boolean
    priceInfo: PaymentStartDto
    couponCode: string | null
    setCouponCode: (value: string | null) => void
    billingCycle: BillingCycle
    setBillingCycle: (billingCycle: BillingCycle) => void
    paymentDetailsOpen: boolean
    cardValid: boolean
    submitting: boolean
    updating: boolean
    business: Business
    targetSubscription: SubscriptionTier
}> = ({
    loading,
    priceInfo,
    couponCode,
    setCouponCode,
    billingCycle,
    setBillingCycle,
    paymentDetailsOpen,
    cardValid,
    submitting,
    updating,
    business,
    targetSubscription,
}) => {
    const { t } = useTranslation('admin')
    const billingCycleOptions = useMemo<SegmentOption<BillingCycle>[]>(
        () => [
            {
                value: 'annual',
                label: <Trans ns="admin">Annual</Trans>,
            },
            {
                value: 'monthly',
                label: <Trans ns="admin">Monthly</Trans>,
            },
        ],
        []
    )
    const formatCurrency = useFormatCurrency(business.languageCode)

    const [couponInputValue, setCouponInputValue] = useState<string>('')
    const [showCouponInput, setShowCouponInput] = useState(false)
    const resetCoupon = useCallback(() => {
        setCouponCode('')
        setCouponInputValue('')
    }, [setCouponCode])

    useEffect(() => {
        if (!couponCode) {
            setCouponInputValue('')
        }
    }, [couponCode])

    const isMobileView = !useMatchMedia(SubscriptionBreakpointMedia)

    return (
        <SummaryContainer className="second-col">
            {loading ? <LoadingScreen /> : null}
            <h3 className="large semibold">
                <Trans ns="admin">Summary</Trans>
            </h3>
            <Flex gap={1.5}>
                <StyledIconContainer>
                    <FontAwesomeIcon icon={faFire} />
                </StyledIconContainer>
                <Flex grow={1} flexDirection="column" alignItems="flex-start" justifyContent="space-between">
                    <strong>Minup Pro</strong>
                    <span className="small grey">
                        {billingCycle === 'monthly' ? (
                            <Trans ns="admin">Monthly plan</Trans>
                        ) : (
                            <Trans ns="admin">Annual plan</Trans>
                        )}
                    </span>
                </Flex>
                <Flex flexDirection="column" alignItems="flex-end" justifyContent="space-between">
                    <span className={classNames(priceInfo?.discount ? 'line-through' : null)}>
                        {formatCurrency(
                            priceInfo.priceBeforeDiscount ? priceInfo.priceBeforeDiscount : priceInfo.price
                        )}
                    </span>
                    {priceInfo.discount ? (
                        <strong className="success">{t(priceInfo.discount)}</strong>
                    ) : (
                        <span>&nbsp;</span>
                    )}
                </Flex>
            </Flex>
            <StyledUnorderedList className="only-desktop">
                <li>
                    <FontAwesomeIcon icon={faCheck} className="mrs" />
                    <Trans ns="admin">Google Calendar sync</Trans>
                </li>
                <li>
                    <FontAwesomeIcon icon={faCheck} className="mrs" />
                    <Trans ns="admin">Group services</Trans>
                </li>
                <li>
                    <FontAwesomeIcon icon={faCheck} className="mrs" />
                    <Trans ns="admin">Custom email templates</Trans>
                </li>
                <li>
                    <FontAwesomeIcon icon={faCheck} className="mrs" />
                    <Trans ns="admin">Client management</Trans>
                </li>
                <li>
                    <TextButtonLink className="link" target="_blank" href={t('landing_page_pricing_url')}>
                        <Trans ns="admin">And many more</Trans>
                    </TextButtonLink>
                </li>
            </StyledUnorderedList>
            <Column>
                <SegmentedPicker grow options={billingCycleOptions} value={billingCycle} onChange={setBillingCycle} />
            </Column>
            <Column spacing={3}>
                {priceInfo.coupon ? (
                    <Coupon spacing={1}>
                        <NeutralIconButton
                            type="button"
                            className="small close-button"
                            icon={faTimes}
                            onClick={resetCoupon}
                        />
                        <CouponIcon size={24} style={{ fontSize: 12.3 }}>
                            <FontAwesomeIcon icon={priceInfo.coupon.icon || 'tag'} />
                        </CouponIcon>
                        <span className="caption semibold">{priceInfo.coupon.code}</span>
                        <span className="caption">{priceInfo.coupon.description}</span>
                    </Coupon>
                ) : (
                    <>
                        {!showCouponInput ? (
                            <div>
                                <TextButton type="button" onClick={() => setShowCouponInput(true)}>
                                    <Trans ns="admin">Add coupon</Trans>
                                </TextButton>
                            </div>
                        ) : null}
                        <CouponInputContainer className={showCouponInput ? 'show' : undefined}>
                            <Input
                                placeholder={t('Coupon code')}
                                value={couponInputValue}
                                onChange={(e) => {
                                    setCouponInputValue(e.target.value)
                                }}
                            />
                            <NeutralButton
                                type="button"
                                onClick={() => {
                                    setCouponCode(couponInputValue)
                                }}
                            >
                                <Trans ns="admin">Apply</Trans>
                            </NeutralButton>
                        </CouponInputContainer>
                    </>
                )}
            </Column>
            <Column spacing={3}>
                <Flex justifyContent="space-between">
                    {targetSubscription === 'pro-trial' ? (
                        <>
                            <span>
                                <Trans ns="admin">Price after trial</Trans>
                            </span>
                            <InlineSpan>
                                <AdminCurrency value={priceInfo.renewPrice} />
                                {priceInfo.coupon ? (
                                    <CouponIcon size={20} className="mls" style={{ fontSize: 10 }}>
                                        <FontAwesomeIcon icon={priceInfo.coupon.icon || 'tag'} />
                                    </CouponIcon>
                                ) : null}
                            </InlineSpan>
                        </>
                    ) : (
                        <>
                            <span>
                                <Trans ns="admin">Subtotal</Trans>
                            </span>
                            <InlineSpan>
                                <AdminCurrency value={priceInfo.price} />
                                {priceInfo.coupon ? (
                                    <CouponIcon size={20} className="mls" style={{ fontSize: 10 }}>
                                        <FontAwesomeIcon icon={priceInfo.coupon.icon || 'tag'} />
                                    </CouponIcon>
                                ) : null}
                            </InlineSpan>
                        </>
                    )}
                </Flex>
                <Flex justifyContent="space-between">
                    <span>
                        <Trans ns="admin">VAT</Trans>
                    </span>
                    <span>{priceInfo.vatPercent}%</span>
                </Flex>
            </Column>
            <HorizontalDivider className="p0" />
            <div>
                <Flex justifyContent="space-between" className="mbs">
                    <strong className="large semibold">
                        <Trans ns="admin">Total due today</Trans>
                    </strong>
                    <strong className="heading small">
                        <AdminCurrency value={priceInfo.grossPrice} />
                    </strong>
                </Flex>
                <div className="small grey">
                    <Trans ns="admin">
                        Renews automatically on{' '}
                        <strong>
                            <LocalDate dateTime={priceInfo.renewDate} />
                        </strong>{' '}
                        for <strong>{{ renewPrice: formatCurrency(priceInfo.renewPrice) }}</strong> +{' '}
                        {{ vatPercent: priceInfo.vatPercent }}% VAT.
                    </Trans>
                </div>
            </div>
            {!isMobileView && paymentDetailsOpen ? (
                <Column>
                    <InputGroup>
                        <FlexLabel style={{ fontWeight: 'normal', fontSize: '16px' }}>
                            <input type="checkbox" required />
                            <div>
                                <Trans ns="admin">
                                    I have read and accept the{' '}
                                    <a target="_blank" rel="noreferrer" href={t('privacy_policy_url')}>
                                        Privacy Policy
                                    </a>{' '}
                                    and{' '}
                                    <a target="_blank" rel="noreferrer" href={t('terms_and_conditions_url')}>
                                        General Terms & Conditions
                                    </a>
                                </Trans>
                                <RequiredIndicator />
                            </div>
                        </FlexLabel>
                    </InputGroup>
                    <LightButton variant="Pro" disabled={cardValid === false || submitting || updating}>
                        {submitting ? (
                            <>
                                <Icon className="mrs" spin icon={faSpinner} />
                                <Trans ns="admin">Processing...</Trans>
                            </>
                        ) : business.subscriptionTier === 'pro' && business.isCancelPending ? (
                            <>
                                <Icon className="mrs" icon={faRocket} />
                                <Trans ns="admin">Renew</Trans>
                            </>
                        ) : business.proTrialAvailable ? (
                            <>
                                <Icon className="mrs only-desktop" icon={faRocket} />
                                <Trans ns="admin">Start free trial</Trans>
                            </>
                        ) : (
                            <>
                                <Icon className="mrs" icon={faRocket} />
                                <Trans ns="admin">Upgrade</Trans>
                            </>
                        )}
                    </LightButton>
                </Column>
            ) : null}
        </SummaryContainer>
    )
}
