import { COUNTRY_CODES } from './country-codes'

// Map country codes to emoji flags using regional indicator symbols: https://en.wikipedia.org/wiki/Regional_indicator_symbol
export const COUNTRY_FLAGS = Object.fromEntries(
    COUNTRY_CODES.map((code) => [
        code,
        code
            .toUpperCase()
            .split('')
            .map((char) => String.fromCodePoint(127397 + char.charCodeAt(0)))
            .join(''),
    ])
)
