import { useEffect } from 'react'

export function useOnScrollParent(element: HTMLElement | null | undefined, callback: (e: Event) => void) {
    useEffect(() => {
        if (element) {
            const parents: HTMLElement[] = []
            let parent = element.parentElement
            while (parent) {
                parents.push(parent)
                parent = parent.parentElement
            }
            const onScroll = (e: Event) => {
                if (e.target === e.currentTarget) {
                    callback(e)
                }
            }
            for (const parent of parents) {
                parent.addEventListener('scroll', onScroll)
            }
            return () => {
                for (const parent of parents) {
                    try {
                        parent.removeEventListener('scroll', onScroll)
                    } catch (_) {}
                }
            }
        }
    }, [callback, element])
}
