import React from 'react'
import styled from '@emotion/styled'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'
import { Trans } from 'react-i18next'

const DontWorryComponent = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        justifyContent: 'center',
    }))
)

export const DontWorry = ({ ...props }) => (
    <DontWorryComponent {...props}>
        <Trans ns="admin">Don’t worry, you can edit this info later</Trans>
    </DontWorryComponent>
)
