import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { fromTheme } from '../../../theme/theme'
import { Input } from './Input'

export const InputPlaceholder = withTheme(styled(Input)`
    :disabled {
        background-color: ${fromTheme('Neutral_100')};
        color: ${fromTheme('Neutral_700')};
        border: 1px solid ${fromTheme('Neutral_400')};
    }
`)
