import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { AdminTheme } from '../../../theme/theme'

export const CouponButtonNavLink = withTheme(
    styled(NavLink)(({ theme }: { theme: AdminTheme }) => ({
        padding: '2px 11px',
        flexShrink: 0,
        boxShadow: `0px 0px 4px 2px rgba(239, 78, 218, 0.25), 
            inset 0px 0px 0px 1px #fff`,
        backgroundColor: '#EF4EDA',
        color: `${theme.FontColor} !important`,
        border: `3px solid #EA15D1`,
        borderRadius: theme.Spacing(1),
        fontFeatureSettings: "'liga' off, 'clig' off",
        ...theme._BodyMediumSemibold,

        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#EA15D1',
        },

        '&:active': {
            backgroundColor: '#BE05A9',
            boxShadow: `0px 0px 4px 2px rgba(239, 78, 218, 0.25),
                inset 0px 0px 0px 1px #fff,
                inset 0px 0px 4px 3px hsla(308, 99%, 17%, 1)`,
        },

        '&:focus-visible': {
            outline: 'none',
            borderColor: 'hsla(308, 82%, 29%, 1)',
            boxShadow: `0px 0px 4px 2px hsla(308, 82%, 29%, 0.5), inset 0px 0px 0px 1px #fff`,
        },

        '&.disabled': {
            backgroundColor: '#EF4EDA',
            boxShadow: `0px 0px 4px 2px rgba(239, 78, 218, 0.25), 
                inset 0px 0px 0px 1px #fff`,
            cursor: 'initial',
        },
    }))
)
