import { useMemo } from 'react'
import { Invoice, InvoiceStatus } from '../../pages/admin/subscription/main/InvoiceHistory'
import { useAuthorizedApi } from './use-authorized-api'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { BillingCycle, SubscriptionTier } from '../../common/types/enums'

interface Coupon {
    billingCycle: BillingCycle
    code: string
    description: string
    discountPercent: number
    icon: IconName | undefined
    validFor: number
    validFrom: string
    validUntil: string
}
export interface PaymentStartDto {
    paymentId: string
    invoiceId: string
    renewDate: string
    price: number
    renewPrice: number
    priceBeforeDiscount: number
    discount: string
    vatPercent: number
    vatAmount: number
    grossPrice: number
    coupon: Coupon
}

export interface PriceInfo {
    monthlyPrice: number
    monthlyPriceBeforeDiscount: number
    annualPrice: number
    annualBasePrice: number
    discount: string
    nextBillingDate?: string
}

export function useSubscriptionApi() {
    const { get, post } = useAuthorizedApi()

    return useMemo(() => {
        return {
            getPrices: () => get<PriceInfo>('/api/admin/subscription/getPrices'),
            listInvoices: () => get<Invoice[]>('/api/admin/subscription/invoices'),
            startTrial: (billingCycle: BillingCycle, countryCode: string, vatId: string, couponCode: string | null) =>
                post<PaymentStartDto>('/api/admin/subscription/startTrial', {
                    targetSubscription: 'pro-trial',
                    billingCycle,
                    countryCode,
                    vatId,
                    couponCode,
                }),
            startPro: (billingCycle: BillingCycle, countryCode: string, vatId: string, couponCode: string | null) =>
                post<PaymentStartDto>('/api/admin/subscription/startPro', {
                    targetSubscription: 'pro',
                    billingCycle,
                    countryCode,
                    vatId,
                    couponCode,
                }),
            updateOrder: (
                targetSubscription: SubscriptionTier | 'pro-resume',
                billingCycle: BillingCycle,
                countryCode: string,
                vatId: string,
                invoiceId: string = '',
                couponCode: string | null
            ) =>
                post<PaymentStartDto>('/api/admin/subscription/updateOrder', {
                    targetSubscription,
                    billingCycle,
                    countryCode,
                    vatId,
                    invoiceId,
                    couponCode,
                }),
            cancel: (answers: any) =>
                post<{
                    subscriptionTier: string
                    proTrialAvailable: boolean
                    isCancelPending: boolean
                    trialEndDate: string
                }>('/api/admin/subscription/cancel', answers),
            finalizePayment: (invoiceId: string) =>
                post<{
                    subscriptionTier: SubscriptionTier
                    billingCycle: BillingCycle
                    proTrialAvailable: boolean
                    isCancelPending: boolean
                    trialEndDate: string
                    status: InvoiceStatus
                }>(`/api/admin/subscription/finalizePayment/${invoiceId}`),
            startCardRegistration: () =>
                post<{ orderId: string; paymentId: string; countryCode: string; zipCode: string }>(
                    '/api/admin/subscription/startCardRegistration'
                ),
            finalizeCardRegistration: (orderId: string) =>
                post<{ ok: boolean; pending: boolean; failed: boolean }>(
                    '/api/admin/subscription/finalizeCardRegistration',
                    {
                        orderId,
                    }
                ),
            getCurrentCardInfo: () =>
                get<{
                    paymentMethod?: {
                        brand: string
                        last4: string
                        expiryYear: string
                        expiryMonth: string
                    }
                }>('/api/admin/subscription/getCurrentCardInfo'),
            switchToMonthly: () => post('/api/admin/subscription/switchToMonthly'),
            switchToAnnual: () => post('/api/admin/subscription/switchToAnnual'),
        }
    }, [get, post])
}
