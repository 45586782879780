import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Flex } from '../../../components/helpers/Flex'
import { AdminTheme } from '../../../theme/theme'
import { FC, ReactNode } from 'react'
import { MinupRibbon } from '../../../components/helpers/MinupRibbon'
import { SuccessAnimation } from './SuccessAnimation'
import Checkmark from '../../../icons/lottie/Checkmark.lottie'
import Bell from '../../../icons/lottie/Bell.lottie'
import { faEnvelope, faExclamationTriangle, faTag } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as DarkThemeLogo } from '../../../icons/DarkThemeLogo.svg'
import { ReactComponent as Logo } from '../../../icons/Logo.svg'
import { Trans } from 'react-i18next'
import { HourDayDisplay } from './HourDayDisplay'
import { NeutralButtonLink } from '../../../components/ui-kit/button/neutral'
import AddToGoogleCalendar from '../../../icons/GoogleCalendar.webp'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { ResultPageContainer, ResultPageCards, ResultPageCard } from './BookingPageLayout'

const SuccessPageLearnMore = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        borderBottomLeftRadius: theme.Spacing(1),
        borderBottomRightRadius: theme.Spacing(1),
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.BorderPrimary,
        padding: theme.Spacing(3),
    }))
)

const AddToGoogleCalendarButton = withTheme(
    styled(NeutralButtonLink)(({ theme }: { theme: AdminTheme }) => ({
        color: theme.ContentPrimary,
        ...theme._BodyMedium,
    }))
)

export const SuccessScreen: FC<{
    googleCalendarLink: string
    clientEmail: string
    business: BusinessPublicDto
    canCancelUntil: number
    approvalRequired: boolean
    transactionMessage?: ReactNode
}> = ({ googleCalendarLink, clientEmail, business, canCancelUntil, approvalRequired, transactionMessage }) => {
    return (
        <>
            <ResultPageContainer grow={1} justifyContent="flex-start">
                {!business.hideMinupBranding ? <MinupRibbon /> : null}
                <SuccessAnimation src={approvalRequired ? Bell : Checkmark} />
                {approvalRequired ? (
                    <h2 className="mtm">
                        <Trans ns="bookingpage">Booking awaiting approval</Trans>
                    </h2>
                ) : (
                    <h2 className="mtm">
                        <Trans ns="bookingpage">Successful booking</Trans>
                    </h2>
                )}
                <ResultPageCards>
                    <ResultPageCard icon={faEnvelope}>
                        {approvalRequired ? (
                            <p>
                                <Trans ns="bookingpage">
                                    {{ businessName: business.name }} received your appointment request.{' '}
                                    <b>Your booking will be final once the service provider approved it.</b> We’ll
                                    notify you by email.
                                </Trans>
                            </p>
                        ) : (
                            <p>
                                <Trans ns="bookingpage">
                                    A calendar invitation has been sent to <b>{{ clientEmail: clientEmail }}</b>.
                                </Trans>
                            </p>
                        )}
                    </ResultPageCard>
                    {transactionMessage ? (
                        <ResultPageCard icon={faCreditCard}>{transactionMessage}</ResultPageCard>
                    ) : null}
                    <ResultPageCard icon={faTag}>
                        <Trans ns="bookingpage">
                            Please check that the email didn’t land under the <b>Promotions</b> tab!
                        </Trans>
                    </ResultPageCard>
                    {canCancelUntil > 0 ? (
                        <ResultPageCard icon={faExclamationTriangle}>
                            <HourDayDisplay hours={business.canCancelUntil} bold />
                        </ResultPageCard>
                    ) : null}
                </ResultPageCards>
                {!approvalRequired && googleCalendarLink ? (
                    <Flex grow={1} pt={3}>
                        <AddToGoogleCalendarButton target="_blank" rel="noreferrer" href={googleCalendarLink}>
                            <img className="mrs" src={AddToGoogleCalendar} alt="" width={16} />
                            <Trans ns="admin">Add to Google Calendar</Trans>
                        </AddToGoogleCalendarButton>
                    </Flex>
                ) : null}
            </ResultPageContainer>
            {!business.hideMinupBranding ? (
                <SuccessPageLearnMore gap={1} flexWrap="wrap">
                    <Flex gap={1}>
                        {business.theme === 'midnight' ||
                        (business.theme === 'custom' && business.customTheme?.IsDarkTheme) ? (
                            <DarkThemeLogo height="20px" width="20px" />
                        ) : (
                            <Logo height="20px" width="20px" />
                        )}
                        <Trans ns="bookingpage">Create your own booking page</Trans>
                    </Flex>
                    <a
                        href={`https://minup.io/${business.bookingPageLanguage}/`}
                        target="_blank"
                        rel="noreferrer"
                        className="underlined"
                    >
                        <Trans ns="bookingpage">Learn more about Minup</Trans>
                    </a>
                </SuccessPageLearnMore>
            ) : null}
        </>
    )
}
