import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { withTheme } from '@emotion/react'

export const ErrorMessage = withTheme(
    styled.span(({ theme }: { theme: AdminTheme }) => ({
        color: theme.ContentDanger,
        ...theme._Caption,
    }))
)
