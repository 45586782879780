import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme, fromTheme } from '../../../theme/theme'
import { Input } from './Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComponentProps, CSSProperties, forwardRef, RefObject, useCallback, useEffect, useState } from 'react'
import { useAdminTheme } from '../../../hooks/use-admin-theme'
import { useOnScrollParent } from '../../../hooks/use-on-scroll-parent'
import { Flex } from '../../helpers/Flex'

const DropDownOptionListUl = withTheme(styled.ul`
    box-sizing: border-box;
    position: absolute;
    top: 105%;
    width: 100%;
    background: ${fromTheme('SurfaceColor')};
    border-radius: 8px;
    border: 1px solid ${fromTheme('BorderPrimary')};
    list-style-type: none;
    margin-top: 0;
    padding: 4px;
    z-index: 9;
    max-height: 268px;
    overflow-y: auto;
    box-shadow: ${fromTheme('Elevation_200')};
    scrollbar-width: auto;
    & > li {
        padding: 8px 16px;
        cursor: pointer;
        border-radius: 8px;
        &:hover {
            background: ${fromTheme('InteractiveNeutralHover')};
        }
        &:active {
            background: ${fromTheme('InteractiveNeutralActive')};
        }
        &.no-match {
            background: none !important;
            cursor: default;
            color: ${fromTheme('Neutral_500')};
        }
    }
`)

export const DropDownOptionList = forwardRef<
    HTMLUListElement,
    {
        inputRef?: RefObject<HTMLElement | undefined>
        dropdownMaxHeight?: number
        dropdownWidth?: number
        fixedPosition?: boolean
    } & ComponentProps<typeof DropDownOptionListUl>
>(({ children, inputRef, dropdownWidth, dropdownMaxHeight = 260, fixedPosition, style, ...rest }, ref) => {
    const [dropdownStyle, setDropdownStyle] = useState<CSSProperties>()
    const [positionReady, setPositionReady] = useState(false)
    const theme = useAdminTheme()

    const setPosition = useCallback(() => {
        let props = {}
        if (inputRef?.current) {
            const bodyRect = document.body.getBoundingClientRect(),
                elemRect = inputRef.current.getBoundingClientRect(),
                offset = elemRect.top - bodyRect.top + elemRect.height + 5,
                positionFromBottom = window.innerHeight - elemRect.bottom,
                positionFromTop = elemRect.top,
                bottomPadding = theme.SpacingValue(3)
            let positioning: CSSProperties = {}
            if (fixedPosition) {
                if (dropdownMaxHeight + bottomPadding >= positionFromBottom && dropdownMaxHeight < positionFromTop) {
                    // If the opener isn't scrolled too far (to bottom)
                    if (positionFromBottom >= -inputRef.current.clientHeight) {
                        positioning = {
                            bottom: positionFromBottom + inputRef.current.clientHeight - theme.SpacingValue(1),
                            top: 'unset',
                        }
                    }
                    // If the opener is scrolled too far (to bottom), position the dropdown to the bottom of the screen
                    else {
                        positioning = {
                            bottom: 0,
                            top: 'unset',
                        }
                    }
                } else if (dropdownMaxHeight + bottomPadding < positionFromBottom) {
                    // If the opener isn't scrolled too far (to top)
                    if (positionFromTop >= -inputRef.current.clientHeight) {
                        positioning = {
                            top: Math.min(offset, bodyRect.height - dropdownMaxHeight - theme.SpacingValue(1)),
                        }
                    }
                    // If there opener is scrolled too far (to top), position the dropdown to the top of the screen
                    else {
                        positioning = {
                            bottom: 'unset',
                            top: 16,
                        }
                    }
                }
                // Else (Non above is true) no place between the opener and top/bottom
                else {
                    positioning = { top: 16, bottom: 'unset' }
                }
                positioning = {
                    ...positioning,
                    left: Math.max(
                        8,
                        Math.min(elemRect.left, dropdownWidth ? bodyRect.width - dropdownWidth - 8 : elemRect.left)
                    ),
                }
            } else {
                if (dropdownMaxHeight + bottomPadding >= positionFromBottom && dropdownMaxHeight < positionFromTop) {
                    positioning = { bottom: inputRef.current.clientHeight - theme.SpacingValue(1), top: 'unset' }
                } else if (dropdownMaxHeight + bottomPadding < positionFromBottom) {
                    positioning = {
                        top: inputRef.current.clientHeight + theme.SpacingValue(1),
                        bottom: 'unset',
                    }
                } else {
                    positioning = {
                        top: 16,
                        bottom: 'unset',
                        position: 'fixed',
                    }
                }
            }

            props = {
                ...props,
                position: fixedPosition ? 'fixed' : 'absolute',
                width: dropdownWidth || inputRef.current.clientWidth,
                maxWidth: bodyRect.width - 16,
                ...positioning,

                maxHeight: dropdownMaxHeight,
                zIndex: 9999,
                ...style,
            }
            setDropdownStyle(props)
            setPositionReady(true)
        }
    }, [dropdownMaxHeight, dropdownWidth, fixedPosition, inputRef, style, theme])

    useEffect(() => {
        setPosition()
    }, [setPosition])

    useOnScrollParent(inputRef?.current, () => setPosition())

    return positionReady ? (
        <DropDownOptionListUl {...rest} style={dropdownStyle} ref={ref}>
            {children}
        </DropDownOptionListUl>
    ) : null
})

export const StyledInput = withTheme(styled(Input)`
    &.open {
        border: 1px solid ${fromTheme('Primary_700')};
        outline: none;
        box-shadow: 0 0 0 4px rgba(55, 114, 255, 0.3);
    }
`)

export const SearchableInputContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        justifyContent: 'flex-start',
        margin: theme.Spacing(0.5),
        '.icon': {
            position: 'absolute',
            left: 12,
            pointerEvents: 'none',
            touchAction: 'none',
            fontSize: 16,
            color: theme.ContentDisabled,
        },
    }))
)

export const SearchableInput = withTheme(
    styled(Input)(({ theme }: { theme: AdminTheme }) => ({
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: theme.Spacing(1),
        padding: `${theme.Spacing(1)} ${theme.Spacing(1.5)} ${theme.Spacing(1)} 36px`,
    }))
)

export const StyledFontAwesomeIcon = withTheme(
    styled(FontAwesomeIcon)(({ theme }: { theme: AdminTheme }) => ({
        position: 'absolute',
        right: '12px',
        cursor: 'pointer',
        color: theme.Neutral_700,
        fontSize: '14px',
        '&.disabled': {
            color: theme.ContentDisabled,
            pointerEvents: 'none',
            touchAction: 'none',
        },
    }))
)
