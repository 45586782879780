export type Variant = 'Primary' | 'Neutral' | 'Secondary1' | 'Secondary2' | 'Secondary3' | 'Secondary4'

export interface ThemeTokens {
    ThemeColor: string
    // Interactive
    //    Primary
    InteractivePrimary: string
    InteractivePrimaryHover: string
    InteractivePrimaryActive: string
    //    Secondary
    InteractiveSecondary: string
    InteractiveSecondaryHover: string
    InteractiveSecondaryActive: string
    //    Neutral
    InteractiveNeutral: string
    InteractiveNeutralHover: string
    InteractiveNeutralActive: string
    //    Danger/Secondary
    InteractiveDangerSecondary: string
    InteractiveDangerSecondaryHover: string
    InteractiveDangerSecondaryActive: string
    //    Inverted
    InteractiveInverted: string
    //    Pro
    InteractivePro: string
    InteractiveProHover: string
    InteractiveProActive: string
    //    Booking page card
    InteractiveBookingPageCard: string
    InteractiveBookingPageCardHover: string
    InteractiveBookingPageCardActive: string
    // Disabled
    InteractiveDisabledDefault: string
    // Focus
    FocusOutline: string
    // Content
    ContentPrimary: string
    ContentSecondary: string
    ContentTertiary: string
    ContentBrand: string
    ContentSuccess: string
    ContentDisabled: string
    ContentWarning: string
    ContentDanger: string
    ContentPro: string
    ContentInfo: string
    ContentInverted: string
    // Background
    BackgroundColor: string
    BackgroundSecondary: string
    BackgroundInfoSecondary: string
    BackgroundOnboarding: string
    BackgroundSuccessPrimary: string
    BackgroundSuccessSecondary: string
    BackgroundNeutralSecondary: string
    BackgroundWarningPrimary: string
    BackgroundWarningSecondary: string
    BackgroundDangerSecondary: string
    BackgroundProSecondary: string
    BackgroundInverted: string
    GradientBusiness: string
    GradientBusinessRepeated: string
    // Surface
    SurfaceColor: string
    // Border
    BorderBrand: string
    BorderPrimary: string
    BroderSecondary: string
    BorderTertiary: string
    BorderPro: string
    BorderInverted: string
    // Brand
    BrandPrimary: string
    // Cover
    Cover1: string
    Cover2: string
    Cover3: string
    // Font
    FontColor: string
    // Animation
    AnimationColor: number[]
    Spacing: (x: number) => string
    SpacingValue: (x: number) => number
    // Border radiuses
    ButtonBorderRadius: string
}

export interface ColorPalette {
    Primary_900: string
    Primary_800: string
    Primary_700: string
    Primary_600: string
    Primary_500: string
    Primary_400: string
    Primary_300: string
    Primary_200: string
    Primary_100: string
    Primary_50: string
    Primary_0: string

    Neutral_900: string
    Neutral_800: string
    Neutral_700: string
    Neutral_600: string
    Neutral_500: string
    Neutral_400: string
    Neutral_300: string
    Neutral_200: string
    Neutral_100: string
    Neutral_50: string
    Neutral_0: string

    Secondary1_900: string
    Secondary1_800: string
    Secondary1_700: string
    Secondary1_600: string
    Secondary1_500: string
    Secondary1_400: string
    Secondary1_300: string
    Secondary1_200: string
    Secondary1_100: string
    Secondary1_50: string
    Secondary1_0: string

    Secondary2_900: string
    Secondary2_800: string
    Secondary2_700: string
    Secondary2_600: string
    Secondary2_500: string
    Secondary2_400: string
    Secondary2_300: string
    Secondary2_200: string
    Secondary2_100: string
    Secondary2_50: string
    Secondary2_0: string

    Secondary3_900: string
    Secondary3_800: string
    Secondary3_700: string
    Secondary3_600: string
    Secondary3_500: string
    Secondary3_400: string
    Secondary3_300: string
    Secondary3_200: string
    Secondary3_100: string
    Secondary3_50: string
    Secondary3_0: string

    Secondary4_900: string
    Secondary4_800: string
    Secondary4_700: string
    Secondary4_600: string
    Secondary4_500: string
    Secondary4_400: string
    Secondary4_300: string
    Secondary4_200: string
    Secondary4_100: string
    Secondary4_50: string
    Secondary4_0: string

    Opacity_100: string
    Opacity_90: string
    Opacity_70: string
    Opacity_50: string
    Opacity_30: string
    Opacity_10: string
}
interface AdminThemeBase {
    Elevation_0: string
    Elevation_100: string
    Elevation_200: string
    Elevation_300: string

    BookingPageAdminBarBackground: string
    BookingPageAvatarBackground: string
    BookingPageBorderStyle: any
    BookingPageCardHoverBorderStyle: any

    DefaultFontFamily: string
    _DefaultFontFamily: object

    HeadingLarge: string
    HeadingMedium: string
    HeadingSmall: string

    _HeadingLarge: any
    _HeadingMedium: any
    _HeadingSmall: any

    BodyLarge: string
    BodyLargeSemibold: string
    BodyMedium: string
    BodyMediumSemibold: string
    BodySmall: string
    BodySmallSemibold: string

    _BodyLarge: any
    _BodyLargeSemibold: any
    _BodyMedium: any
    _BodyMediumSemibold: any
    _BodySmall: any
    _BodySmallSemibold: any

    Caption: string
    CaptionSemibold: string

    _Caption: any
    _CaptionSemibold: any

    Code: string

    BookingPageBreakPoint: string
    BookingPageBreakPointReverse: string
    BreakPoint: string
    BreakPointReverse: string
}

export interface AdminTheme extends ColorPalette, AdminThemeBase, ThemeTokens {}

export const fromTheme =
    (prop: keyof AdminTheme) =>
    (props: { theme: AdminTheme }): string =>
        props.theme[prop]
