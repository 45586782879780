import { ComponentProps, FC, useCallback, useMemo } from 'react'
import { COUNTRY_CODES } from '../../../dictionaries/country-codes'
import { AutocompleteItem, AutocompleteInput } from './AutocompleteInput'
import { useGetCountryName } from '../../../pages/admin/subscription/hooks/use-get-country-name'
import { Flex } from '../../helpers/Flex'
import { COUNTRY_FLAGS } from '../../../dictionaries/country-flags'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

const CountryListItem = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        gap: theme.Spacing(1),
        justifyContent: 'flex-start',
        padding: `${theme.Spacing(0.5)} 0`,
    }))
)

export const CountryCodeSelector: FC<
    Omit<ComponentProps<typeof AutocompleteInput>, 'value' | 'onChange' | 'getOptionsAsync'> & {
        value: string
        onChange: (value: string, label: string) => void
    }
> = ({ value, onChange, ...rest }) => {
    const getCountryName = useGetCountryName()

    const countries = useMemo((): AutocompleteItem[] => {
        return COUNTRY_CODES.map((code) => ({
            label: getCountryName(code),
            value: code,
        })).sort((item1, item2) => (item1.label || '').localeCompare(item2.label))
    }, [getCountryName])

    const renderCountryOption = useCallback((item: AutocompleteItem) => {
        return (
            <CountryListItem>
                <span>{COUNTRY_FLAGS[item.value as string]}</span>
                <span>{item.label}</span>
            </CountryListItem>
        )
    }, [])

    return (
        <AutocompleteInput
            {...rest}
            disableAutocomplete
            searchable
            options={countries}
            value={value}
            renderItem={renderCountryOption}
            required
            onChange={(e: any) => onChange(e.value, e.label)}
        />
    )
}
