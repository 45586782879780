import { FC, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import moment from 'moment'
import { Flex } from '../../helpers/Flex'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'
import { StarterBannerBreakpoint } from './StarterCouponBanner'

const TimeBox = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        width: 20,
        height: 34,
        backgroundColor: 'rgba(255, 255, 255, 0.60)',
        borderRadius: theme.Spacing(1),
        ...theme._BodySmallSemibold,
        [StarterBannerBreakpoint]: {
            width: 28,
        },
    }))
)

export const StarterBannerTimer: FC<{ createdAt: moment.Moment; onTimerEnd: () => void }> = ({
    createdAt,
    onTimerEnd,
}) => {
    const endTime = useMemo(() => moment.utc(createdAt).add(1, 'day'), [createdAt])
    const [timeLeft, setTimeLeft] = useState<moment.Duration | null>(null)

    useEffect(() => {
        const updateTimer = () => {
            const now = moment().utc()
            const diff = moment.duration(endTime.diff(now))
            setTimeLeft(diff)
            if (diff.asMilliseconds() <= 0) {
                setTimeLeft(moment.duration(0))
                clearInterval(interval)
                onTimerEnd()
            } else {
                setTimeLeft(diff)
            }
        }

        updateTimer()
        const interval = setInterval(updateTimer, 1000)

        return () => clearInterval(interval)
    }, [createdAt, endTime, onTimerEnd])

    const hours = String(Math.max(0, Math.floor(timeLeft?.asHours() || 0))).padStart(2, '0')
    const minutes = String(Math.max(0, timeLeft?.minutes() || 0)).padStart(2, '0')
    const seconds = String(Math.max(0, timeLeft?.seconds() || 0)).padStart(2, '0')

    return (
        <Flex gap={0.5}>
            <TimeBox>{hours[0]}</TimeBox>
            <TimeBox>{hours[1]}</TimeBox>
            <span>:</span>
            <TimeBox>{minutes[0]}</TimeBox>
            <TimeBox>{minutes[1]}</TimeBox>
            <span>:</span>
            <TimeBox>{seconds[0]}</TimeBox>
            <TimeBox>{seconds[1]}</TimeBox>
        </Flex>
    )
}
