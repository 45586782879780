import moment from 'moment-timezone'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import i18n, { default_lang, changeLanguage, LanguageCodes } from '../i18n'
import { useBusinessApi } from './apis/use-business-api'
import { useSetBusiness } from './use-business'
import { useSetFeatureFlags } from './use-feature-flags'

export function useGetCurrentBusiness(onSuccess?: () => void) {
    const updateBusiness = useSetBusiness()
    const setFeautuerFlags = useSetFeatureFlags()
    const { get } = useBusinessApi()
    const history = useHistory()

    return useCallback(
        async (skipCheckEmail?: boolean, skipSetLanguage?: boolean) => {
            try {
                const tz = moment.tz.guess(true)
                const response = await get(default_lang || LanguageCodes.En, tz, skipCheckEmail)
                const business = response.data.business
                if (!business.urlHandle || !business.name) {
                    history.push('/admin/wizard')
                }

                if (!skipSetLanguage && i18n.language !== business.languageCode) {
                    changeLanguage(business.languageCode)
                }

                updateBusiness(business)
                setFeautuerFlags(response.data.featureFlags)

                onSuccess && onSuccess()
            } catch (error: any) {
                if (error.response && error.response.status === 409) {
                    updateBusiness(error.response.data)
                    history.push('/admin/wizard/pair')
                    onSuccess && onSuccess()
                } else {
                    throw error
                }
            }
        },
        [get, history, onSuccess, setFeautuerFlags, updateBusiness]
    )
}
