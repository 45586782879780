import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { Column } from '../../../components/admin/layout/Column'
import { Flex } from '../../../components/helpers/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const BookingPageOuterContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        height: '100%',
        overflowY: 'scroll',
        backgroundColor: theme.BackgroundColor,
    }))
)

export const BookingPageContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        flexDirection: 'column',
        rowGap: 0,
        height: '100%',
        [theme.BreakPoint]: {
            minHeight: '100%',
            height: 'auto',
            display: 'grid',
            alignContent: 'start',
            justifyContent: 'center',
            gridTemplateAreas: "'header' 'events' 'main' '.'",
            padding: `${theme.Spacing(3)} 0`,
            gridTemplateColumns: '740px auto',
            gridTemplateRows: 'auto auto',
        },

        [theme.BookingPageBreakPoint]: {
            gridTemplateAreas: "'header events' 'main events' '. events'",
        },
    }))
)

export const BookingPageContent = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        backgroundColor: theme.SurfaceColor,
        color: theme.ContentPrimary,
        gridArea: 'main',
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.BreakPointReverse]: {
            borderBottom: `1px solid ${theme.BroderSecondary}`,
        },
        [theme.BreakPoint]: {
            minHeight: 'auto',
            overflow: 'visible',
            borderRadius: theme.Spacing(1),
            ...theme.BookingPageBorderStyle,
        },
    }))
)

export const BookingPageWithStickyFooter = withTheme(
    styled(BookingPageContent)(({ theme, noHeight }: { theme: AdminTheme; noHeight?: boolean }) => ({
        flexDirection: 'column',
        height: noHeight ? undefined : '100%',
        display: noHeight ? 'contents' : 'flex',
        '> div': {
            backgroundColor: theme.SurfaceColor,
        },
        [theme.BreakPoint]: {
            display: 'flex',
            height: 'auto',
            '> div:first-of-type': {
                borderRadius: theme.Spacing(1),
            },
        },
    }))
)

export const ResultPageContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        flexDirection: 'column',
        padding: theme.Spacing(2.5),
        [theme.BreakPoint]: {
            padding: theme.Spacing(3),
            minHeight: 'auto',
        },
    }))
)

export const ResultPageCards = withTheme(
    styled(Column)(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        marginTop: theme.Spacing(3),
        gap: theme.Spacing(2.5),
        [theme.BreakPoint]: {
            gap: theme.Spacing(3),
        },
    }))
)

export type ResultPageCardVariant = 'Danger'

export const ResultPageCardContainer = withTheme(
    styled(Flex)(({ theme, variant }: { variant?: ResultPageCardVariant; theme: AdminTheme }) => ({
        width: '100%',
        padding: `${theme.Spacing(2.5)} ${theme.Spacing(3)}`,
        backgroundColor: variant === 'Danger' ? theme.BackgroundDangerSecondary : theme.BackgroundSecondary,
        borderRadius: theme.Spacing(1.5),
        gap: theme.Spacing(1),
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        [theme.BreakPoint]: {
            alignItems: 'center',
            flexDirection: 'row',
        },
    }))
)

export const ResultPageCard: FC<{ icon: IconProp; variant?: ResultPageCardVariant }> = ({
    icon,
    variant,
    children,
}) => {
    return (
        <ResultPageCardContainer variant={variant}>
            <ResultPageCardIconContainer variant={variant}>
                <FontAwesomeIcon icon={icon} />
            </ResultPageCardIconContainer>
            <span>{children}</span>
        </ResultPageCardContainer>
    )
}

export const ResultPageCardIconContainer = withTheme(
    styled(Flex)(({ theme, variant }: { theme: AdminTheme; variant?: ResultPageCardVariant }) => ({
        width: 32,
        height: 32,
        borderRadius: theme.Spacing(1),
        padding: '6px 6px',
        backgroundColor: variant === 'Danger' ? theme.InteractiveDangerSecondaryHover : theme.InteractiveSecondaryHover,
        color: variant === 'Danger' ? theme.ContentDanger : theme.InteractivePrimary,
        [FontAwesomeIcon as any]: {
            width: 20,
            height: 20,
        },
    }))
)

export const BookingPageFooterContainer = withTheme(
    styled.div(
        ({
            theme,
            forceSpaceBetween,
            hideBackButton,
        }: {
            theme: AdminTheme
            forceSpaceBetween?: boolean
            hideBackButton?: boolean
        }) => ({
            position: 'sticky',
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            padding: theme.Spacing(2.5),
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: theme.BorderPrimary,
            backgroundColor: theme.SurfaceColor,
            gap: theme.Spacing(2),
            '.continue': {
                flexGrow: 1,
            },

            justifyContent: forceSpaceBetween ? 'space-between' : undefined,

            [theme.BreakPoint]: {
                padding: theme.Spacing(3),
                justifyContent: hideBackButton ? 'flex-end' : 'space-between',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                '.continue': {
                    flexGrow: 0,
                },
            },
        })
    )
)
