export function normalizeHandle(handle: string, trimDashes: boolean = true): string {
    let result = handle
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLocaleLowerCase()
        .replace(/[^-a-z0-9]/g, '-')
    if (trimDashes) {
        result = result.replace(/-[-]+/g, '-').replace(/^[-]+|[-]+$/g, '')
    }
    return result
}
