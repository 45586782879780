import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { PrimaryButton } from '../../../../components/ui-kit/button/primary'
import { WizardStepContent } from '../WizardStepContent'

import LinkingAnimation from '../../../../icons/linking.gif'
import { Flex } from '../../../../components/helpers/Flex'
import { Trans, useTranslation } from 'react-i18next'
import { NeutralButton } from '../../../../components/ui-kit/button/neutral'
import { useGetCurrentBusiness } from '../../../../hooks/use-get-current-business'
import { Input } from '../../../../components/ui-kit/comopnents/Input'
import { useBusinessApi } from '../../../../hooks/apis/use-business-api'
import { useToast } from '../../../../hooks/use-toast'
import { Business } from '../../../../store/auth/types'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '../../../../components/ui-kit/comopnents/Icon'
import { useBusiness } from '../../../../hooks/use-business'
import { useModal } from '../../../../hooks/use-modal'
import { TextButton } from '../../../../components/ui-kit/button/TextButton'

export const Pair: FunctionComponent = () => {
    const business = useBusiness() as Business & { isGoogle: boolean }
    const getCurrentBusiness = useGetCurrentBusiness()
    const [pairing, setPairing] = useState(false)
    const [code, setCode] = useState('')
    const { t } = useTranslation('admin')
    const confirmation = useModal()
    const businessApi = useBusinessApi()
    const toast = useToast()

    const skip = useCallback(async () => {
        if (
            await confirmation.show({
                title: t('Create new account?'),
                text: t('We’ll create a new account with the same email address.'),
                primaryButtonText: t('Create new account'),
                primaryButtonClass: 'danger',
                cancelButtonText: t('Cancel'),
            })
        ) {
            getCurrentBusiness(true)
        }
    }, [confirmation, getCurrentBusiness, t])

    useEffect(() => {
        ;(async () => {
            if (business?.id) {
                await getCurrentBusiness()
                window.location.href = '/admin'
            }
        })()
    }, [business?.id, getCurrentBusiness, pairing])

    const pair = useCallback(async () => {
        await businessApi.sendVerificationEmail()
        setPairing(true)
    }, [businessApi])

    const resend = useCallback(
        async (e: any) => {
            e.preventDefault()
            await businessApi.sendVerificationEmail()
            toast.success(t('We’ve emailed you a new verification code.'))
            setPairing(true)
        },
        [businessApi, t, toast]
    )

    const verify = useCallback(async () => {
        try {
            await businessApi.verifyCode(code)
            await getCurrentBusiness()
            window.location.href = '/admin'
        } catch (e) {
            toast.error(t('Incorrect code.') + ' ' + t('Please try again.'))
        }
    }, [businessApi, code, getCurrentBusiness, t, toast])

    const back = useCallback(() => {
        setPairing(false)
    }, [])

    return (
        <>
            <WizardStepContent id="wizard-step-welcome">
                {pairing ? (
                    <>
                        <h3 className="mb">
                            <Trans ns="admin">Login verification</Trans>
                        </h3>
                        <div className="">
                            <p className="mbs">
                                <Trans
                                    ns="admin"
                                    i18nKey={'Enter the code we emailed you to <strong>{{email}}</strong>.'}
                                    values={{
                                        email: business.email,
                                    }}
                                />
                            </p>
                            <p className="mbs">
                                <Input
                                    placeholder={t('Email code')}
                                    value={code}
                                    maxLength={6}
                                    onChange={(e) => setCode(e.currentTarget.value)}
                                />
                            </p>
                            <p className="mbs">
                                <Trans ns="admin">Didn't get the email?</Trans>{' '}
                                <TextButton type="button" onClick={resend}>
                                    <Trans ns="admin">Resend code to my email</Trans>
                                </TextButton>
                            </p>
                        </div>
                        <Flex className="mt" justifyContent="space-between">
                            <NeutralButton onClick={back}>
                                <Trans ns="admin">Back</Trans>
                            </NeutralButton>
                            <PrimaryButton onClick={verify} disabled={code.length !== 6}>
                                <Trans ns="admin">Submit verification</Trans>
                            </PrimaryButton>
                        </Flex>
                    </>
                ) : (
                    <>
                        <h3 className="mb centered">
                            {business.isGoogle ? (
                                <Trans ns="admin">Login with your Google account</Trans>
                            ) : (
                                <Trans ns="admin">Login with your email and password</Trans>
                            )}
                        </h3>
                        <div className="centered">
                            <p>
                                {business.isGoogle ? (
                                    <Trans
                                        ns="admin"
                                        values={{ email: business.email }}
                                        i18nKey="So far you've been using your email and password to log in. If you want to log in with your Google account, please verify that <strong>{{ email }}</strong> is your email address."
                                    />
                                ) : (
                                    <Trans
                                        ns="admin"
                                        values={{ email: business.email }}
                                        i18nKey="So far you've been using your Google account to log in. If you want to log in with your email and password, please verify that <strong>{{ email }}</strong> is your email address."
                                    />
                                )}
                            </p>
                        </div>
                        <div className="centered">
                            <img src={LinkingAnimation} role="presentation" alt="" />
                        </div>
                        <Flex className="mt" justifyContent="space-between">
                            <NeutralButton onClick={skip}>
                                <Trans ns="admin">Create new account</Trans>
                            </NeutralButton>
                            <PrimaryButton onClick={pair}>
                                <Trans ns="admin">Next</Trans>
                                <Icon className="end-icon" icon={faArrowRight} />
                            </PrimaryButton>
                        </Flex>
                    </>
                )}
            </WizardStepContent>
        </>
    )
}
