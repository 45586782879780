import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useBusiness } from '../../../hooks/use-business'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Flex } from '../../helpers/Flex'
import { AdminTheme } from '../../../theme/theme'
import { useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useMatchMedia } from '../../../hooks/use-match-media'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { copyToClipboard } from '../../../services/copy-to-clipboard'
import { useToast } from '../../../hooks/use-toast'
import moment from 'moment'
import { classNames } from '../../../services/class-names'
import { Column } from '../layout/Column'
import { CouponButtonNavLink } from './CouponButton'
import Rocket from '../../../icons/new-year-sale/Rocket.webp'
import Smoke from '../../../icons/new-year-sale/Smoke.webp'
import React from 'react'
import { asClickable } from '../../helpers/as-clickable'

const NewYearSaleBannerBreakpointMedia = '(min-width:1280px)'
const NewYearSaleBannerBreakpoint = `@media${NewYearSaleBannerBreakpointMedia}`

const BannerTitle = withTheme(
    styled.h1(({ theme }: { theme: AdminTheme }) => ({
        display: 'inline-flex',
        columnGap: theme.Spacing(1),
        rowGap: 0,
        ...theme._HeadingSmall,
        flexWrap: 'wrap',
    }))
)

const NewYearSaleBannerContainer = withTheme(
    styled.section(
        ({ theme, onCheckout, bigScreen }: { theme: AdminTheme; onCheckout: boolean; bigScreen: boolean }) => ({
            display: 'flex',
            padding: `${theme.Spacing(1.5)} ${theme.Spacing(2.5)}`,
            background: `linear-gradient(to right, #000000 0%, #1a4466 110%)`,
            justifyContent: bigScreen && onCheckout ? 'space-between' : onCheckout ? 'center' : 'space-between',
            alignItems: 'center',
            gap: theme.Spacing(2),
            color: theme.ContentInverted,
            maxHeight: 70,
        })
    )
)

const Discount = styled.span(() => ({
    color: 'hsla(308, 86%, 74%, 1)',
}))

const CopyToClipboard = asClickable(
    styled(Flex)(() => ({
        flexDirection: 'column',
        flexShrink: 0,
        cursor: 'pointer',
    }))
)

const StyledFontAwesomeIcon = withTheme(
    styled(FontAwesomeIcon)(({ theme }: { theme: AdminTheme }) => ({
        fontSize: 8,
        color: 'hsla(234, 24%, 25%, 1)',
        [NewYearSaleBannerBreakpoint]: {
            fontSize: 16,
            color: theme.ContentInverted,
        },
    }))
)

const CopyIconBackground = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        width: 16,
        height: 16,
        backgroundColor: theme.ContentInverted,
        borderRadius: '50%',
    }))
)

const SmokeAbsoluteImage = withTheme(
    styled.img(({ theme }: { theme: AdminTheme }) => ({
        position: 'absolute',
        height: 62,
        marginBottom: 12,
        left: -124,
    }))
)

const RocketAbsoluteImage = withTheme(
    styled.img(({ theme }: { theme: AdminTheme }) => ({
        position: 'absolute',
        height: 55,
        right: -70,
    }))
)

export const NewYearSaleBanner: FC = () => {
    const business = useBusiness()!
    const coupon = useMemo<{ name: string }>(() => ({ name: 'START2025' }), [])
    const couponLink = useMemo(() => {
        const query = new URLSearchParams()
        query.append('coupon', coupon.name)
        return `/admin/subscription/checkout/${business.proTrialAvailable ? 'trial' : 'pro'}?${query.toString()}`
    }, [business.proTrialAvailable, coupon.name])
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (
            moment.utc(business.createdAt).isBefore(moment.utc('2024-12-19', 'YYYY-MM-DD')) &&
            business.subscriptionTier === 'free'
        ) {
            setShow(true)
        }
    }, [business.createdAt, business.isCancelPending, business.subscriptionTier])

    const location = useLocation()
    const onCheckout = useMemo(() => location.pathname.includes('checkout'), [location.pathname])
    const bigScreen = useMatchMedia(NewYearSaleBannerBreakpointMedia)
    const toast = useToast()
    const { t } = useTranslation('admin')
    const CopyIconWrapper = useMemo(() => {
        if (onCheckout && bigScreen) {
            return React.Fragment
        } else {
            return CopyIconBackground
        }
    }, [bigScreen, onCheckout])

    const copyCouponName = useCallback(() => {
        copyToClipboard(coupon.name)
        toast.success(t('Coupon copied to clipboard.'), '', 3)
    }, [coupon.name, t, toast])

    return show ? (
        <NewYearSaleBannerContainer onCheckout={onCheckout} bigScreen={bigScreen}>
            {bigScreen ? (
                <>
                    <BannerTitle>
                        <Trans ns="admin">
                            NEW YEAR SALE <Discount>UNTIL JAN. 31</Discount>
                        </Trans>
                    </BannerTitle>
                    <Flex>
                        <img src={Smoke} height={68} alt="" style={{ marginBottom: 12 }} />
                        <img src={Rocket} height={55} alt="" />
                    </Flex>
                </>
            ) : null}
            {onCheckout ? (
                <>
                    {bigScreen ? (
                        <Flex gap={2} shrink={0}>
                            <h2 className="heading small">
                                <Trans ns="admin">GET 25% OFF MINUP PRO</Trans>
                            </h2>
                        </Flex>
                    ) : null}
                    <Flex className="relative">
                        {bigScreen ? null : <SmokeAbsoluteImage src={Smoke} alt="" />}
                        <CopyToClipboard onClick={copyCouponName}>
                            {bigScreen ? null : (
                                <h1 className="heading small">
                                    <Trans ns="admin">25% OFF</Trans>
                                </h1>
                            )}
                            <Flex gap={0.5}>
                                <Flex gap={0.5}>
                                    <span className={classNames(bigScreen ? 'bodymedium' : 'caption semibold')}>
                                        <Trans ns="admin">Coupon</Trans>:
                                    </span>
                                    <span className={classNames(bigScreen ? 'heading small' : 'caption semibold')}>
                                        {coupon.name}
                                    </span>
                                </Flex>
                                <CopyIconWrapper>
                                    <StyledFontAwesomeIcon icon={faCopy} />
                                </CopyIconWrapper>
                            </Flex>
                        </CopyToClipboard>
                        {bigScreen ? null : <RocketAbsoluteImage src={Rocket} alt="" />}
                    </Flex>
                </>
            ) : (
                <>
                    {bigScreen ? null : (
                        <Column spacing={0}>
                            <h1 className="heading small">
                                <Trans ns="admin">25% OFF</Trans>
                            </h1>
                            <Discount className="caption semibold">
                                <Trans ns="admin">Offer valid until Jan. 31</Trans>
                            </Discount>
                        </Column>
                    )}
                    <Flex gap={1} shrink={0}>
                        {bigScreen ? (
                            <h2 className="heading small">
                                <Trans ns="admin">GET 25% OFF MINUP PRO</Trans>
                            </h2>
                        ) : null}
                        <CouponButtonNavLink to={couponLink}>
                            {bigScreen ? (
                                <Trans ns="admin">ACTIVATE COUPON</Trans>
                            ) : (
                                <Trans i18nKey="new_year_sale_activate_text_mobile" ns="admin" />
                            )}
                        </CouponButtonNavLink>
                    </Flex>
                </>
            )}
        </NewYearSaleBannerContainer>
    ) : null
}
