import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import moment from 'moment'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Flex } from '../../../components/helpers/Flex'
import { PrimaryButton, PrimaryButtonNavLink } from '../../../components/ui-kit/button/primary'
import { classNames } from '../../../services/class-names'
import { EventPublicDto, OccurrencePublicDto } from '../../../store/events/types'
import { AdminTheme } from '../../../theme/theme'
import { getEventBookUrl } from './helpers/get-event-book-url'

const Container = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        padding: theme.Spacing(2.5),
        [theme.BreakPoint]: {
            padding: theme.Spacing(3),
        },
    }))
)

const DetailsTitle = withTheme(
    styled.h4(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: '0 1 44px',
        '.month': {
            textTransform: 'uppercase',
            ...theme._BodySmall,
            color: theme.ThemeColor,
            marginBottom: theme.Spacing(0.5),
        },
        '.day': {
            ...theme._BodyMediumSemibold,
            color: theme.ContentPrimary,
        },
    }))
)

const EventDateContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        '.date': { marginBottom: '4px', textTransform: 'capitalize' },
        '.occupancy': {
            color: theme.ContentSecondary,
        },
        '.occupancy.danger': {
            color: theme.ContentDanger,
        },
    }))
)

const RowContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        '&:not(:last-of-type)': {
            borderBottom: `1px solid ${theme.BorderPrimary}`,
            marginBottom: '24px',
        },
        padding: '0 24px 24px 24px',
        [theme.BreakPointReverse]: {
            flexWrap: 'wrap',
            padding: '0 0 24px 0',
            '.row-action': {
                marginTop: '8px',
                flexBasis: '100% !important',
                flexGrow: 1,
            },
        },
    }))
)

const OccurrenceRow: FC<{
    event: EventPublicDto
    occurrence: OccurrencePublicDto
    timezone: string
    isEmbed?: boolean
}> = ({ event, occurrence, timezone, isEmbed }) => {
    const start = moment.tz(occurrence.from, timezone)
    const end = moment.tz(occurrence.to, timezone)
    const params = useParams<{ handle: string; eventId: string }>()
    const remaining = Math.max(0, occurrence.maxParticipants - occurrence.currentParticipants)
    return (
        <RowContainer>
            <DetailsTitle>
                <span className="month">{start.format('MMM')}</span>
                <span className="day">{start.format('D')}</span>
            </DetailsTitle>
            <EventDateContainer className="pl" grow={1} flexDirection="column" alignItems="flex-start">
                <b className="date">
                    {start.format('dddd')}, {start.format('HH:mm')} - {end.format('HH:mm')}
                </b>
                <span className={classNames('small', 'occupancy', remaining === 1 ? 'danger' : undefined)}>
                    {occurrence.currentParticipants === -1 ? (
                        <>
                            <Trans ns="bookingpage">Max. attendees</Trans>: {occurrence.maxParticipants}
                        </>
                    ) : (
                        <>
                            {remaining} <Trans ns="bookingpage">free seats</Trans>
                        </>
                    )}
                </span>
            </EventDateContainer>
            {occurrence.soldOut ? (
                <PrimaryButton
                    type="button"
                    disabled
                    className="row-action small"
                    style={{ flexBasis: '140px', cursor: 'not-allowed' }}
                >
                    <Trans ns="bookingpage">Sold out</Trans>
                </PrimaryButton>
            ) : (
                <PrimaryButtonNavLink
                    className="row-action small"
                    to={getEventBookUrl(isEmbed, params.handle, event, occurrence.id)}
                    style={{ flexBasis: '140px' }}
                >
                    <Trans ns="bookingpage">Book</Trans>
                </PrimaryButtonNavLink>
            )}
        </RowContainer>
    )
}

export const EventDates: FC<{ event: EventPublicDto; timezone: string; isEmbed?: boolean }> = ({
    event,
    timezone,
    isEmbed,
}) => {
    return (
        <Container>
            <h1>
                <Trans ns="bookingpage">Occurrences</Trans>
            </h1>
            <Flex flexDirection="column" className="mt" alignItems="stretch">
                {event.occurrences.map((occurrence) => (
                    <OccurrenceRow
                        key={occurrence.id}
                        occurrence={occurrence}
                        event={event}
                        timezone={timezone}
                        isEmbed={isEmbed}
                    />
                ))}
            </Flex>
        </Container>
    )
}
