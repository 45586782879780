import styled from '@emotion/styled'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'

export const WizardStepContent = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        padding: '20px 24px',

        [theme.BreakPoint]: {
            padding: theme.Spacing(3),
            boxShadow: 'none',
        },
    }))
)
