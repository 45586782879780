import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { ComponentProps, FC } from 'react'
import { PaymentMethodWarning } from '../subscription/PaymentMethodWarning'
import { StarterCouponBanner } from '../subscription/StarterCouponBanner'
import { NewYearSaleBanner } from '../subscription/NewYearSaleBanner'

const AdminMainContainer = withTheme(
    styled.div(({ theme, noPadding }: { theme: AdminTheme; noPadding?: boolean }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        maxWidth: '100%',

        flexGrow: 1,
        overflowY: 'auto',
        paddingBottom: noPadding ? undefined : theme.Spacing(4),
    }))
)

export const AdminMain: FC<ComponentProps<typeof AdminMainContainer>> = ({ children, ...rest }) => {
    return (
        <AdminMainContainer id="admin-main" {...rest}>
            <PaymentMethodWarning />
            <StarterCouponBanner />
            <NewYearSaleBanner />
            {children}
        </AdminMainContainer>
    )
}
