import { Formik, Form, Field } from 'formik'
import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Flex } from '../../../components/helpers/Flex'
import { PrimaryButton } from '../../../components/ui-kit/button/primary'
import { FormikInput } from '../../../components/ui-kit/comopnents/Input'
import { RequiredIndicator } from '../../../components/ui-kit/comopnents/RequiredIndicator'
import { FormikTextarea } from '../../../components/ui-kit/comopnents/Textarea'
import { Column } from '../../../components/admin/layout/Column'
import { AutocompleteInput, AutocompleteItem } from '../../../components/ui-kit/comopnents/AutocompleteInput'
import { emailProviders } from '../../../dictionaries/email-providers'
import { classNames } from '../../../services/class-names'
import { EmailWarningMessage } from './EmailWarningMessage'
import { getEmailSuggestion } from '../../../components/helpers/get-email-suggestion'
import { InputGroup } from '../../../components/ui-kit/comopnents/InputGroup'
import { Footer } from './Footer'
import { MinupRibbon } from '../../../components/helpers/MinupRibbon'
import { BookingPageTitle } from './BookingPageTitle'
import { Content } from './Content'
import { Separator } from './Separator'
import { CountryCodeSelector } from '../../../components/ui-kit/comopnents/CountryCodeSelector'
import { useGetCountryName } from '../../admin/subscription/hooks/use-get-country-name'
import { BusinessPublicDto } from '../../../bookingpage/types/business'

export type ContactInfo = {
    clientName: string
    clientEmail: string
    clientPhone: string
    clientNote?: string
    seats: number
    billingName: string
    billingCountry: string
    billingCountryCode: string
    billingCity: string
    billingAddress: string
    billingZipCode: string
    isBusiness: boolean
    taxID: string
    invoiceRequested: boolean
}

export const ContactInfoScreen: FC<{
    contactInfo: ContactInfo
    maxSeatsPerBooking: number
    summaryCard?: ReactNode
    business: BusinessPublicDto
    isEmbed?: boolean
    onSubmit: (contactInfo: ContactInfo) => void
}> = ({ contactInfo, summaryCard, business, maxSeatsPerBooking, isEmbed, onSubmit }) => {
    const [suggestion, setSuggestion] = useState('')

    const handleBlur = useCallback((e: any) => {
        const emailSuggestion = getEmailSuggestion(e.target.value, emailProviders)
        setSuggestion(emailSuggestion)
    }, [])

    const { t } = useTranslation('bookingpage')
    const getCountryName = useGetCountryName()

    const seatOptions = useMemo<AutocompleteItem[]>(() => {
        const options: AutocompleteItem[] = []
        for (let i = 1; i <= maxSeatsPerBooking; ++i) {
            options.push({
                label: `${i}`,
                value: i,
            })
        }
        return options
    }, [maxSeatsPerBooking])

    const taxIdRef = useRef<HTMLInputElement>()
    const scrollToTaxId = useCallback(() => {
        setTimeout(() => {
            if (taxIdRef.current) {
                taxIdRef.current.scrollIntoView({ behavior: 'auto' })
                taxIdRef.current.focus()
            }
        })
    }, [])

    return (
        <>
            <Formik
                initialValues={{
                    clientName: contactInfo?.clientName || '',
                    clientEmail: contactInfo?.clientEmail || '',
                    clientPhone: contactInfo?.clientPhone || '',
                    clientNote: contactInfo?.clientNote || '',
                    seats: contactInfo?.seats || 1,
                    tnc: false,
                    billingName: contactInfo?.billingName || '',
                    billingCountry: contactInfo?.billingCountry || getCountryName(business.countryCode || 'HU'),
                    billingCountryCode: contactInfo?.billingCountryCode || business.countryCode || 'HU',
                    billingCity: contactInfo?.billingCity || '',
                    billingAddress: contactInfo?.billingAddress || '',
                    billingZipCode: contactInfo?.billingZipCode || '',
                    isBusiness: contactInfo?.isBusiness || false,
                    taxID: contactInfo?.taxID || '',
                    invoiceRequested: Boolean(business.invoiceSettings && !business.invoiceSettings?.enableReceipts),
                }}
                onSubmit={onSubmit}
            >
                {({ touched, isSubmitting, values, setFieldValue }) => (
                    <>
                        <Content>
                            {!business.hideMinupBranding ? <MinupRibbon /> : null}
                            <BookingPageTitle title={<Trans ns="bookingpage">Add your contact info</Trans>} />
                            {summaryCard ? summaryCard : null}
                            <Form id="contact-info">
                                <Column>
                                    <Flex gap={2} flexWrap="wrap">
                                        <Flex grow={1}>
                                            <InputGroup>
                                                <label htmlFor="name">
                                                    <Trans ns="bookingpage">Name</Trans>
                                                    <RequiredIndicator />
                                                </label>
                                                <Field
                                                    component={FormikInput}
                                                    name="clientName"
                                                    id="name"
                                                    onBlur={(e: any) => {
                                                        if (!touched.billingName) {
                                                            setFieldValue('billingName', e.target.value)
                                                        }
                                                    }}
                                                    placeholder={t('Enter your full name')}
                                                    required
                                                />
                                            </InputGroup>
                                        </Flex>

                                        {maxSeatsPerBooking > 1 ? (
                                            <Flex style={{ minWidth: 150 }} growMobile={1}>
                                                <InputGroup>
                                                    <label htmlFor="seats">
                                                        <Trans ns="bookingpage">Number of participants</Trans>
                                                    </label>
                                                    <AutocompleteInput
                                                        value={values['seats']}
                                                        options={seatOptions}
                                                        disableAutocomplete
                                                        onChange={(item) => setFieldValue('seats', item.value)}
                                                    />
                                                </InputGroup>
                                            </Flex>
                                        ) : null}
                                    </Flex>
                                    <InputGroup>
                                        <label htmlFor="email">
                                            <Trans ns="bookingpage">Email</Trans>
                                            <RequiredIndicator />
                                        </label>
                                        <Field
                                            component={FormikInput}
                                            name="clientEmail"
                                            id="email"
                                            required
                                            type="email"
                                            placeholder={t('Enter your email address')}
                                            onBlur={handleBlur}
                                            className={classNames(suggestion ? 'warning' : null)}
                                        />
                                        {suggestion ? (
                                            <EmailWarningMessage
                                                suggestion={suggestion}
                                                onAccept={(value: string) => {
                                                    setFieldValue('clientEmail', value)
                                                    setSuggestion('')
                                                }}
                                            />
                                        ) : null}
                                    </InputGroup>
                                    <InputGroup>
                                        <label htmlFor="phone">
                                            <Trans ns="bookingpage">Phone</Trans>
                                            {business.phoneRequired ? <RequiredIndicator /> : null}
                                        </label>
                                        <Field
                                            component={FormikInput}
                                            name="clientPhone"
                                            id="phone"
                                            placeholder={t('Enter your phone number')}
                                            required={business.phoneRequired}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <label htmlFor="note">
                                            <Trans ns="bookingpage">Note</Trans>
                                        </label>
                                        <Field
                                            component={FormikTextarea}
                                            name="clientNote"
                                            id="note"
                                            placeholder={t('Leave a note (optional)')}
                                        />
                                    </InputGroup>
                                    {business.invoiceSettings?.enableReceipts ? (
                                        <>
                                            <InputGroup forCheckbox>
                                                <Field
                                                    component={FormikInput}
                                                    type="checkbox"
                                                    id="requestInvoice"
                                                    checked={values['invoiceRequested']}
                                                    onChange={(e: any) => {
                                                        const newValue = e.target.checked
                                                        setFieldValue('invoiceRequested', newValue)
                                                        if (!newValue && values.isBusiness) {
                                                            setFieldValue('isBusiness', false)
                                                            setFieldValue('taxID', '')
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="requestInvoice" className="bodymedium">
                                                    <Trans ns="bookingpage">Request an invoice</Trans>
                                                </label>
                                            </InputGroup>
                                        </>
                                    ) : null}
                                    {values['invoiceRequested'] ? (
                                        <>
                                            <Separator>
                                                <Trans ns="bookingpage">Billing details</Trans>
                                            </Separator>
                                            <InputGroup>
                                                <label htmlFor="billingName">
                                                    <Trans ns="bookingpage">Billing name</Trans>
                                                    <RequiredIndicator />
                                                </label>
                                                <Field
                                                    component={FormikInput}
                                                    name="billingName"
                                                    id="billingName"
                                                    required
                                                />
                                            </InputGroup>
                                            <Flex gap={1.5} flexWrap="wrap">
                                                <InputGroup inline>
                                                    <label id="billing-country-label">
                                                        <Trans ns="bookingpage">Country</Trans>
                                                        <RequiredIndicator />
                                                    </label>
                                                    <CountryCodeSelector
                                                        id="billing-country"
                                                        aria-labelledby="billing-country-label"
                                                        value={values.billingCountryCode}
                                                        onChange={(value, label) => {
                                                            setFieldValue('billingCountryCode', value)
                                                            setFieldValue('billingCountry', label)
                                                        }}
                                                    />
                                                </InputGroup>
                                                <InputGroup inline>
                                                    <label htmlFor="billingCity">
                                                        <Trans ns="bookingpage">City</Trans>
                                                        <RequiredIndicator />
                                                    </label>
                                                    <Field
                                                        component={FormikInput}
                                                        name="billingCity"
                                                        id="billingCity"
                                                        required
                                                    />
                                                </InputGroup>
                                            </Flex>
                                            <Flex gap={1.5} flexWrap="wrap">
                                                <InputGroup inline grow={3}>
                                                    <label htmlFor="billingAddress">
                                                        <Trans ns="bookingpage">Street address</Trans>
                                                        <RequiredIndicator />
                                                    </label>
                                                    <Field
                                                        component={FormikInput}
                                                        name="billingAddress"
                                                        id="billingAddress"
                                                        required
                                                    />
                                                </InputGroup>
                                                <InputGroup inline grow={1}>
                                                    <label htmlFor="billingZipCode">
                                                        <Trans ns="bookingpage">Zip code</Trans>
                                                        <RequiredIndicator />
                                                    </label>
                                                    <Field
                                                        component={FormikInput}
                                                        name="billingZipCode"
                                                        id="billingZipCode"
                                                        required
                                                    />
                                                </InputGroup>
                                            </Flex>
                                            {business.invoiceSettings?.enableInvoicingForCompanies ? (
                                                <>
                                                    <InputGroup forCheckbox>
                                                        <Field
                                                            component={FormikInput}
                                                            type="checkbox"
                                                            name="isBusiness"
                                                            id="isBusiness"
                                                            checked={values.isBusiness}
                                                            onChange={(e: any) => {
                                                                setFieldValue('isBusiness', e.target.checked)
                                                                if (e.target.checked === true) {
                                                                    scrollToTaxId()
                                                                }
                                                            }}
                                                        />
                                                        <label htmlFor="isBusiness" className="bodymedium">
                                                            <Trans ns="bookingpage">Invoice for company</Trans>
                                                        </label>
                                                    </InputGroup>
                                                    {values.isBusiness ? (
                                                        <InputGroup>
                                                            <label htmlFor="taxId">
                                                                <Trans ns="bookingpage">Tax ID</Trans>
                                                                <RequiredIndicator />
                                                            </label>
                                                            <Field name="taxID" id="taxID" placeholder="XX000000000">
                                                                {(props: any) => (
                                                                    <FormikInput required {...props} ref={taxIdRef} />
                                                                )}
                                                            </Field>
                                                            <span className="small subtle">
                                                                <Trans ns="bookingpage">Required for businesses</Trans>
                                                            </span>
                                                        </InputGroup>
                                                    ) : null}
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}
                                </Column>
                            </Form>
                        </Content>
                        <Footer
                            nextButton={
                                <PrimaryButton
                                    className="continue"
                                    form="contact-info"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    <Trans ns="bookingpage">Booking overview</Trans>
                                </PrimaryButton>
                            }
                            back={() => window.history.back()}
                        />
                    </>
                )}
            </Formik>
        </>
    )
}
