import { useSelector } from 'react-redux'
import { AuthState } from '../store/auth/types'
import { BillingCycle, SubscriptionTier } from '../common/types/enums'

export interface SubscriptionInfo {
    subscriptionTier?: SubscriptionTier
    billingCycle?: BillingCycle
    eligibleForInitialDiscount?: boolean
    proTrialAvailable?: boolean
    isCancelPending?: boolean
    trialEndDate?: string
    isLifetimePro?: boolean
}

export function useSubscriptionInfo(): SubscriptionInfo {
    return useSelector<{ auth: AuthState }, SubscriptionInfo>((state) =>
        state.auth.business
            ? {
                  subscriptionTier: state.auth.business.subscriptionTier,
                  billingCycle: state.auth.business.billingCycle,
                  eligibleForInitialDiscount: state.auth.business.eligibleForInitialDiscount,
                  proTrialAvailable: state.auth.business.proTrialAvailable,
                  trialEndDate: state.auth.business.trialEndDate,
                  isCancelPending: state.auth.business.isCancelPending,
                  isLifetimePro: state.auth.business.isLifetimePro,
              }
            : {}
    )
}
