import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { faArrowRight, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons'
import DOMPurify from 'dompurify'
import { FC, ReactNode, useState, useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Flex } from '../../../components/helpers/Flex'
import { NeutralIconButton, NeutralIconButtonNavLink } from '../../../components/ui-kit/button/NeutralIconButton'
import { getImageUrl } from '../../../services/get-image-url'
import { AdminTheme } from '../../../theme/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIcons } from '../../../hooks/use-icons'
import { useEmojis } from '../../../hooks/use-emojis'
import { useAdminTheme } from '../../../hooks/use-admin-theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { classNames } from '../../../services/class-names'
import { DefaultAvatar } from '../../../components/ui-kit/comopnents/Avatar'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { ServicePublicDto } from '../../../bookingpage/types/service'

const ServiceCardContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        border: '1px solid',
        borderColor: theme.BorderPrimary,
        borderRadius: 12,
        padding: 16,
        cursor: 'pointer',
        '.card-content': {
            '.info': {
                maxWidth: '100%',
                overflow: 'hidden',
                h4: {
                    maxWidth: '100%',
                    overflow: 'hidden',
                    wordWrap: 'break-word',
                    color: theme.ContentPrimary,
                },
                span: {
                    color: theme.ContentSecondary,
                },
            },
        },
        img: {
            border: `1px solid ${theme.BorderPrimary}`,
            borderRadius: 8,
            marginRight: theme.Spacing(2),
            backgroundColor: theme.Cover1,
        },
        '.iconContainer': {
            a: {
                svg: {
                    cursor: 'pointer',
                },
            },
        },
        span: {
            marginTop: 8,
            color: theme.ThemeColor,
            cursor: 'pointer',
        },
        p: {
            marginTop: 8,
        },

        '&:hover': {
            ...theme.BookingPageCardHoverBorderStyle,
        },
    }))
)

const ServiceIllustration = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        width: 56,
        height: 56,
        flexShrink: 0,
        borderRadius: 8,
        marginRight: theme.Spacing(2),
        backgroundColor: theme.InteractiveSecondaryHover,
    }))
)

const ServiceList = withTheme(
    styled.ul(({ theme }: { theme: AdminTheme }) => ({
        marign: 0,
        marginTop: theme.Spacing(2),
        paddingLeft: theme.Spacing(2),
    }))
)

export const ServiceCard: FC<{
    business: BusinessPublicDto
    name: string
    description?: string
    childServices?: string[]
    subTitle: string | ReactNode
    url: string
    imagePublicId: string | undefined
    filtered?: boolean
    service?: ServicePublicDto
    selected: boolean
    onToggleSelect: (service: ServicePublicDto) => void
}> = ({
    business,
    name,
    description,
    childServices,
    subTitle,
    url,
    imagePublicId,
    filtered,
    service,
    selected,
    onToggleSelect,
}) => {
    const [expand, setExpand] = useState(false)
    const { t } = useTranslation('bookingpage')
    const history = useHistory()
    const htmlDescription = useMemo(() => {
        const purified = DOMPurify.sanitize(description || '')
        const test = document.createElement('span')
        test.innerHTML = purified
        return test.textContent?.trim() ? purified : ''
    }, [description])

    useEffect(() => {
        if (filtered) {
            setExpand(true)
        } else {
            setExpand(false)
        }
    }, [filtered])

    const icons = useIcons()
    const emojis = useEmojis()
    const theme = useAdminTheme()

    const canAdd = service && business?.allowMultipleServiceBooking
    const onClick = useCallback(
        (e) => {
            e.stopPropagation()
            if (canAdd) {
                onToggleSelect(service)
            } else {
                history.push(url)
            }
        },
        [canAdd, history, onToggleSelect, service, url]
    )

    return (
        <ServiceCardContainer
            className="w100"
            role="listitem"
            alignItems="center"
            justifyContent="flex-start"
            onClick={onClick}
        >
            <Flex className="w100" flexDirection="column" alignItems="flex-start">
                <Flex className="card-content" justifyContent="space-around" alignItems="center" role="landmark">
                    {imagePublicId?.startsWith('icon') ? (
                        <ServiceIllustration>
                            <FontAwesomeIcon
                                icon={icons[imagePublicId.split('::')[1] as keyof typeof icons]['icon'] as IconProp}
                                style={{ fontSize: 24 }}
                                color={theme.InteractivePrimary}
                            />
                        </ServiceIllustration>
                    ) : imagePublicId?.startsWith('emoji') ? (
                        <ServiceIllustration>
                            <h1 className="large">
                                {emojis[imagePublicId.split('::')[1] as keyof typeof emojis]['emoji']}
                            </h1>
                        </ServiceIllustration>
                    ) : (
                        <img
                            src={getImageUrl(imagePublicId, 'bookingpageservice', DefaultAvatar)}
                            role="none"
                            alt=""
                            width="56"
                            height="56"
                        />
                    )}
                    <Flex
                        grow={1}
                        className="info"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        style={{ minHeight: 58 }}
                    >
                        <h4>{name}</h4>
                        <span>{subTitle}</span>
                    </Flex>
                </Flex>
                {(htmlDescription || childServices) && (
                    <span
                        tabIndex={0}
                        role="button"
                        onKeyPress={(e) => {
                            if (e.key === ' ' || e.key === 'Enter') {
                                e.preventDefault()
                                e.stopPropagation()
                                setExpand(!expand)
                            }
                        }}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setExpand(!expand)
                        }}
                    >
                        {expand ? t('Hide details') : t('Show details')}
                    </span>
                )}
                {expand &&
                    (childServices ? (
                        <ServiceList>
                            {childServices.map((service, index) => (
                                <li className="small" key={index}>
                                    {service}
                                </li>
                            ))}
                        </ServiceList>
                    ) : (
                        <p className="small" dangerouslySetInnerHTML={{ __html: htmlDescription }} />
                    ))}
            </Flex>
            <div className="iconContainer">
                {canAdd ? (
                    <NeutralIconButton
                        icon={selected ? faCheck : faPlus}
                        onClick={onClick}
                        className={classNames('booking-page-card', selected ? 'selected' : null)}
                    />
                ) : (
                    <NeutralIconButtonNavLink aria-label={t('Select service')} to={url} icon={faArrowRight} />
                )}
            </div>
        </ServiceCardContainer>
    )
}
