import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Moment } from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { captureException } from '../../../appInsights'
import { ContactInfo } from '../components/ContactInfoScreen'
import { useDispatch } from 'react-redux'
import { createAction2 } from '../../../store/utils/create-action'
import { BookingPageActionTypes } from '../../../store/booking-page/actions'
import moment from 'moment'
import { ERROR_MESSAGE_SEPARATOR, getErrorMessage } from '../../../services/getErrorMessage'
import { useToast } from '../../../hooks/use-toast'
import { Slot } from './ServicePage'
import { OverviewScreen } from '../components/OverviewScreen'
import { SuccessScreen } from '../components/SuccessScreen'
import { useBeforeUnload } from '../../../hooks/use-before-unload'
import { ServicePublicDto } from '../../../bookingpage/types/service'
import { BusinessPublicDto } from '../../../bookingpage/types/business'

export const ServiceOverview: FC<{
    service: ServicePublicDto
    additionalServices: ServicePublicDto[]
    contactInfo: ContactInfo
    answers: string
    day: Moment
    selectedSlot: Slot
    business: BusinessPublicDto
    isEmbed?: boolean
}> = ({ contactInfo, answers, service, additionalServices, day, selectedSlot, business, isEmbed }) => {
    const [googleCalendarLink, setGoogleCalendarLink] = useState('')
    const { handle, serviceId } = useParams<{ handle: string; serviceId: string }>()
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const { t: at } = useTranslation('admin')
    const history = useHistory()

    useEffect(() => {
        return () => {
            if (success && history.action === 'POP') {
                history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}`)
            }
        }
    }, [handle, history, isEmbed, success])

    useBeforeUnload(!sending && !success)

    useEffect(() => {
        if (!contactInfo || !selectedSlot) {
            history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}/service/${serviceId}`)
        }
    }, [contactInfo, handle, history, isEmbed, selectedSlot, serviceId, success])

    const dispatch = useDispatch()
    const onSuccess = useCallback(() => {
        dispatch(createAction2({ type: BookingPageActionTypes.BOOKING_SUCCESSFUL }))
    }, [dispatch])

    const toast = useToast()
    const onSchedule = useCallback(
        async (contactInfo: ContactInfo, clientTimezone: string, recaptchaToken: string | null | undefined) => {
            let from = moment.unix(selectedSlot!.from).utc().tz(business.timezone)
            let to = moment.unix(selectedSlot!.to).utc().tz(business.timezone)
            setSending(true)
            const response = await fetch(`/api/book/${handle}/schedule`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...contactInfo,
                    clientTimezone,
                    serviceId: service!.id,
                    additionalServiceIds: additionalServices.map((service) => service.id),
                    from,
                    to,
                    answers,
                    recaptchaToken,
                    isEmbed,
                }),
            })
            const data = await response.clone().json()

            if (response.ok) {
                setGoogleCalendarLink(data.googleCalendarLink)
                onSuccess()
                setSuccess(true)
            } else {
                if (response.status === 400) {
                    try {
                        const r = await response.clone().json()
                        const errorMessage = (await getErrorMessage(r.error, at)).split(ERROR_MESSAGE_SEPARATOR)
                        toast.error(errorMessage[0], errorMessage[1] || '')
                    } catch {
                        const responseText = await response.clone().text()
                        captureException(new Error('Appointment schedule failed'))
                        toast.error(await getErrorMessage(responseText, at), '')
                    }
                } else {
                    const responseText = await response.clone().text()
                    captureException(new Error('Appointment schedule failed'))
                    toast.error(await getErrorMessage(responseText, at), '')
                }
            }
            setSending(false)
        },
        [selectedSlot, business.timezone, handle, service, additionalServices, answers, isEmbed, onSuccess, at, toast]
    )

    return success ? (
        <SuccessScreen
            googleCalendarLink={googleCalendarLink}
            clientEmail={contactInfo.clientEmail}
            business={business}
            canCancelUntil={business.canCancelUntil}
            approvalRequired={service.approvalRequired}
        />
    ) : (
        <OverviewScreen
            service={service}
            additionalServices={additionalServices}
            contactInfo={contactInfo}
            business={business}
            selectedSlot={selectedSlot}
            onSubmit={onSchedule}
        />
    )
}
