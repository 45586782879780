import { FC, ReactNode, useEffect } from 'react'
import { TextButton } from '../../../components/ui-kit/button/TextButton'
import { Icon } from '../../../components/ui-kit/comopnents/Icon'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Trans } from 'react-i18next'
import { BookingPageFooterContainer } from './BookingPageLayout'

export const Footer: FC<{
    nextButton: ReactNode
    summary?: ReactNode
    forceSpaceBetween?: boolean
    scrollOnAppear?: boolean
    back?: () => void
    hideBackButton?: boolean
}> = ({ nextButton, summary, forceSpaceBetween, scrollOnAppear, back, hideBackButton }) => {
    useEffect(() => {
        if (scrollOnAppear) {
            document.getElementById('booking-page-footer')?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [scrollOnAppear])

    return (
        <BookingPageFooterContainer
            id="booking-page-footer"
            hideBackButton={hideBackButton}
            forceSpaceBetween={hideBackButton ? undefined : forceSpaceBetween}
        >
            {back ? (
                !hideBackButton ? (
                    <TextButton onClick={back} className="only-desktop">
                        <Icon icon={faChevronLeft} className="mrs" />
                        <Trans ns="bookingpage">Back</Trans>
                    </TextButton>
                ) : null
            ) : (
                summary
            )}
            {nextButton}
        </BookingPageFooterContainer>
    )
}
