import { FC, useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { LoadingScreen } from '../../../components/ui-kit/comopnents/LoadingScreen'
import { Flex } from '../../../components/helpers/Flex'
import { ServiceDates } from './ServiceDates'
import moment from 'moment'
import { ServiceForm } from './ServiceForm'
import { Form as IForm } from '../../../store/forms/types'
import { ServiceContactInfo } from './ServiceContactInfo'
import { ServiceOverview } from './ServiceOverview'
import { ContactInfo } from '../components/ContactInfoScreen'
import { ClientTimezoneProvider } from '../../../hooks/useClientTimezone'
import { NotFoundPage } from '../NotFound'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { ServicePublicDto } from '../../../bookingpage/types/service'

export interface Slot {
    from: number
    to: number
}

export const ServicePage: FC<{ business: BusinessPublicDto; hideTitle?: boolean; directServiceLink?: boolean }> = ({
    business,
    hideTitle,
    directServiceLink,
}) => {
    const params = useParams<{ handle: string; serviceId: string }>()
    const [service, setService] = useState<ServicePublicDto | undefined>()
    const [additionalServices, setAdditionalServices] = useState<ServicePublicDto[]>([])
    const [loading, setLoading] = useState(true)
    const [form, setForm] = useState<IForm | null>(null)
    const [contactInfo, SetContactInfo] = useState<ContactInfo | undefined>(undefined)
    const [answers, setAnswers] = useState<string>('[]')

    const [day, setDay] = useState(moment())
    const [selectedSlot, setSelectedSlot] = useState<Slot | undefined>(undefined)

    useEffect(() => {
        moment.locale(business.bookingPageLanguage)
        const query = new URLSearchParams(window.location.search)
        const additionalServiceIds = query.getAll('additionalServiceIds').map((id) => parseInt(id, 10))
        ;(async () => {
            const serviceFromBusiness = business.services.find((service) => service.id === parseInt(params.serviceId))
            if (serviceFromBusiness) {
                setService(serviceFromBusiness)
            } else {
                const response = await fetch(`/api/book/byhandle/${params.handle}/services/${params.serviceId}`)
                if (response.ok) {
                    const serviceFromRequest = await response.json()
                    setService(serviceFromRequest)
                }
            }
            setAdditionalServices(business.services.filter((service) => additionalServiceIds.includes(service.id!)))
            const formsQuery = new URLSearchParams()
            for (const serviceId of additionalServiceIds) {
                formsQuery.append('additionalServiceId', `${serviceId}`)
            }
            const formResponse = await fetch(
                `/api/book/${params.handle}/getForm/${params.serviceId}?${query.toString()}`
            )
            if (formResponse.ok) {
                const form = await formResponse.json()
                setForm(form)
            }
            setLoading(false)
        })()
    }, [business.bookingPageLanguage, business.services, params.handle, params.serviceId])

    return (
        <>
            {loading ? (
                <Flex className="p" alignItems="center" justifyContent="center">
                    <LoadingScreen className="no-background" style={{ position: 'relative' }} />
                </Flex>
            ) : service ? (
                <>
                    <ClientTimezoneProvider business={business} service={service}>
                        <Switch>
                            <Route path="/book/:handle/service/:serviceId" exact>
                                <ServiceDates
                                    service={service}
                                    additionalServices={additionalServices}
                                    skipForm={form === null}
                                    hideTitle={hideTitle}
                                    directServiceLink={directServiceLink}
                                    business={business}
                                    day={day}
                                    setDay={setDay}
                                    selectedSlot={selectedSlot!}
                                    selectSlot={setSelectedSlot}
                                />
                            </Route>
                            <Route path="/book/:handle/service/:serviceId/form" exact>
                                <ServiceForm
                                    service={service}
                                    additionalServices={additionalServices}
                                    form={form!}
                                    business={business}
                                    day={day}
                                    selectedSlot={selectedSlot!}
                                    answers={answers}
                                    setAnswers={setAnswers}
                                />
                            </Route>
                            <Route path="/book/:handle/service/:serviceId/checkout" exact>
                                <ServiceContactInfo
                                    service={service}
                                    additionalServices={additionalServices}
                                    contactInfo={contactInfo!}
                                    setContactInfo={SetContactInfo}
                                    business={business}
                                    day={day}
                                    selectedSlot={selectedSlot!}
                                />
                            </Route>
                            <Route path="/book/:handle/service/:serviceId/overview" exact>
                                <ServiceOverview
                                    service={service}
                                    additionalServices={additionalServices}
                                    contactInfo={contactInfo!}
                                    day={day}
                                    selectedSlot={selectedSlot!}
                                    business={business}
                                    answers={answers}
                                />
                            </Route>
                            <Route path="/embed/:handle/service/:serviceId" exact>
                                <ServiceDates
                                    service={service}
                                    additionalServices={additionalServices}
                                    skipForm={form === null}
                                    hideTitle={hideTitle}
                                    directServiceLink={directServiceLink}
                                    business={business}
                                    day={day}
                                    setDay={setDay}
                                    selectedSlot={selectedSlot!}
                                    selectSlot={setSelectedSlot}
                                    isEmbed
                                />
                            </Route>
                            <Route path="/embed/:handle/service/:serviceId/form" exact>
                                <ServiceForm
                                    service={service}
                                    additionalServices={additionalServices}
                                    form={form!}
                                    business={business}
                                    day={day}
                                    selectedSlot={selectedSlot!}
                                    answers={answers}
                                    setAnswers={setAnswers}
                                    isEmbed
                                />
                            </Route>
                            <Route path="/embed/:handle/service/:serviceId/checkout" exact>
                                <ServiceContactInfo
                                    service={service}
                                    additionalServices={additionalServices}
                                    contactInfo={contactInfo!}
                                    setContactInfo={SetContactInfo}
                                    business={business}
                                    day={day}
                                    selectedSlot={selectedSlot!}
                                    isEmbed
                                />
                            </Route>
                            <Route path="/embed/:handle/service/:serviceId/overview" exact>
                                <ServiceOverview
                                    service={service}
                                    additionalServices={additionalServices}
                                    contactInfo={contactInfo!}
                                    day={day}
                                    selectedSlot={selectedSlot!}
                                    business={business}
                                    answers={answers}
                                />
                            </Route>
                        </Switch>
                    </ClientTimezoneProvider>
                </>
            ) : (
                <NotFoundPage />
            )}
        </>
    )
}
