import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComponentProps, FC } from 'react'
import { Trans } from 'react-i18next'
import { useClientTimezone } from '../../hooks/useClientTimezone'
import { Callout } from '../../components/ui-kit/comopnents/Callout'
import { TextButton } from '../../components/ui-kit/button/TextButton'
import { Flex } from '../../components/helpers/Flex'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../theme/theme'
import { BusinessPublicDto } from '../../bookingpage/types/business'

const StyledCallout = withTheme(
    styled(Callout)(({ theme }: { theme: AdminTheme }) => ({
        color: theme.Neutral_800,
    }))
)

export const TimezoneWarning: FC<
    { business: BusinessPublicDto; disableChange?: boolean } & Omit<ComponentProps<typeof Callout>, 'variant'>
> = ({ business, disableChange, ...props }) => {
    const { overridden, different, guessed, clientTimezone, changeToBusinessTimezone, changeToClientTimezone } =
        useClientTimezone()

    const showingInCurrent = (
        <StyledCallout variant="Secondary2" {...props}>
            <FontAwesomeIcon className="icon" icon={faGlobe} />
            <Flex flexDirection="column" alignItems="flex-start" gap={1}>
                <Trans ns="bookingpage">
                    You can see the times in your own timezone ({{ timezone: clientTimezone }})
                </Trans>
                {!disableChange && (
                    <TextButton className="bodymedium" type="button" onClick={() => changeToBusinessTimezone()}>
                        <Trans ns="bookingpage">
                            Change to the business' timezone ({{ timezone: business.timezone }})
                        </Trans>
                    </TextButton>
                )}
            </Flex>
        </StyledCallout>
    )

    const showingInLocal = (
        <StyledCallout variant="Secondary2" {...props}>
            <FontAwesomeIcon className="icon" icon={faGlobe} />
            <Flex flexDirection="column" alignItems="flex-start" gap={1}>
                <Trans ns="bookingpage">
                    You can see the times in the timezone of the business ({{ timezone: business.timezone }})
                </Trans>
                {!disableChange && (
                    <TextButton className="bodymedium" type="button" onClick={() => changeToClientTimezone()}>
                        <Trans ns="bookingpage">Change to my own timezone ({{ timezone: guessed }})</Trans>
                    </TextButton>
                )}
            </Flex>
        </StyledCallout>
    )

    return overridden || different ? (clientTimezone === guessed ? showingInCurrent : showingInLocal) : null
}
