import { FC, ReactNode, useMemo } from 'react'
import { Column } from '../../components/admin/layout/Column'
import { Trans } from 'react-i18next'
import { ServiceCard } from './components/ServiceCard'
import { useFormatMinutes } from '../../hooks/use-format-minutes'
import { BusinessPublicDto } from '../../bookingpage/types/business'
import { ServicePublicDto } from '../../bookingpage/types/service'

interface CategoryDisplayObject {
    name: string
    subTitle: ReactNode
    services: string[]
    url: string
    imagePublicId: string
}

export const ServiceList: FC<{
    business: BusinessPublicDto
    services: ServicePublicDto[]
    embedded?: boolean
    categoryId?: string
    filtered: boolean
    selectedServices: ServicePublicDto[]
    onToggleService: (service: ServicePublicDto) => void
}> = ({ business, services, selectedServices, embedded, categoryId, filtered, onToggleService }) => {
    const categories = useMemo<CategoryDisplayObject[]>(() => {
        const categories = [] as CategoryDisplayObject[]
        if (categoryId !== undefined) {
            return categories
        }

        for (const category of business.serviceCategories) {
            const categoryServices = services.filter((service) => service.categoryId === category.id)
            if (categoryServices.length === 0) {
                continue
            }
            categories.push({
                name: category.name,
                subTitle: (
                    <Trans ns="bookingpage" values={{ count: categoryServices.length }}>
                        {{ count: categoryServices.length }} services
                    </Trans>
                ),
                services: categoryServices.map((service) => service.name),
                url: (embedded ? '/embed/' : '/book/') + business.urlHandle + '/category/' + category.id,
                imagePublicId: category.imagePublicId,
            })
        }

        return categories
    }, [business.serviceCategories, business.urlHandle, categoryId, embedded, services])
    const uncategorized = useMemo(() => {
        return services.filter((service) =>
            categoryId !== undefined
                ? service.categoryId === categoryId
                : !service.categoryId ||
                  business.serviceCategories.find((category) => category.id === service.categoryId) === undefined
        )
    }, [business.serviceCategories, categoryId, services])

    const formatMinutes = useFormatMinutes()

    return (
        <Column spacing={3}>
            {categories.map((category, index) => (
                <ServiceCard
                    business={business}
                    key={index}
                    name={category.name}
                    childServices={category.services}
                    subTitle={category.subTitle}
                    url={category.url}
                    imagePublicId={category.imagePublicId}
                    filtered={filtered}
                    selected={false}
                    onToggleSelect={onToggleService}
                />
            ))}
            {uncategorized.map((service, index) => (
                <ServiceCard
                    business={business}
                    key={index}
                    name={service.name}
                    description={service.description}
                    subTitle={formatMinutes(service.duration) + (service.priceText ? `| ${service.priceText}` : '')}
                    imagePublicId={service.imagePublicId}
                    url={(embedded ? '/embed/' : '/book/') + business.urlHandle + '/service/' + service.id}
                    selected={selectedServices.some((selectedService) => service.id === selectedService.id)}
                    onToggleSelect={onToggleService}
                    service={service}
                />
            ))}
        </Column>
    )
}
