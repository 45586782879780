import { Auth0Lock } from 'auth0-lock'
import { createInstance, default_lang } from '../i18n'

const LOGIN_REDIRECT_URI_KEY = 'LOGIN_REDIRECT_URI'

let lockInstance: Auth0LockStatic | null = null

export const getLockInstance = async (): Promise<Auth0LockStatic> => {
    if (lockInstance) {
        return lockInstance
    }

    const t = await createInstance(default_lang)

    lockInstance = new Auth0Lock(process.env.REACT_APP_AUTH0_CLIENT_ID!, process.env.REACT_APP_AUTH0_DOMAIN!, {
        language: default_lang,
        avatar: null,
        closable: false,
        mustAcceptTerms: true,
        additionalSignUpFields: [
            {
                type: 'hidden',
                name: 'preferredLanguage',
                value: default_lang,
            },
        ],
        theme: {
            logo: 'https://minup.io/wp-content/uploads/2022/03/logo-with-text.png',
            primaryColor: '#3772FF',
        },
        languageDictionary: {
            title: '',
            signUpTitle: '',
            signUpLabel: t('Sign Up', { ns: 'admin' }),
            signUpWithLabel: t('Sign up with %s', { ns: 'admin' }),
            signUpSubmitLabel: t('Sign Up', { ns: 'admin' }),
            signUpTerms: t(
                'I have read the and I accept the <a target="_blank" href="https://minup.io/en/general-terms-and-conditions/">Terms of Service</a> and the <a target="_blank" href="https://minup.io/en/privacy-policy/">Privacy Policy</a>.',
                { ns: 'admin' }
            ),
        },
        auth: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
            redirectUrl: `${window.location.origin}/admin`,
            responseType: 'token',
        },
        configurationBaseUrl: 'https://cdn.eu.auth0.com',
    })

    return lockInstance
}

export function saveRedirectUri() {
    sessionStorage.setItem(LOGIN_REDIRECT_URI_KEY, btoa(window.location.pathname + window.location.search))
}

export function getAndRemoveRedirectUri(): string | undefined {
    const saved = sessionStorage.getItem(LOGIN_REDIRECT_URI_KEY)
    sessionStorage.removeItem(LOGIN_REDIRECT_URI_KEY)
    return saved ? atob(saved) : undefined
}
