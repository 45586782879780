import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export function useGetCountryName() {
    const { t } = useTranslation('admin')

    return useCallback(
        (countryCode: string) => {
            return t('country_' + countryCode)
        },
        [t]
    )
}
