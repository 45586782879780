import styled from '@emotion/styled'
import { faSpinner, faChevronUp, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { ButtonHTMLAttributes, HtmlHTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '../../helpers/Flex'
import {
    DropDownOptionList,
    StyledInput,
    StyledFontAwesomeIcon,
    SearchableInput,
    SearchableInputContainer,
} from './DropDownInputBase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputlikeDiv } from './Input'
import { v4 as uuid } from 'uuid'
import React from 'react'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'
import { withSpacing } from '../../helpers/with-spacing'
import { insensitiveContains } from '../../../services/insensitive-compare'
import { classNames } from '../../../services/class-names'

const StyledInputlikeDiv = withTheme(
    styled(InputlikeDiv)(({ theme }: { theme: AdminTheme }) => ({
        '&.disabled': {
            border: `1px solid ${theme.Neutral_500}`,
            background: theme.BackgroundNeutralSecondary,
            pointerEvents: 'none',
            touchAction: 'none',
        },
    }))
)

const AutocompleteContainer = styled(Flex)`
    position: relative;
`

const SectionHeaderContainer = withTheme(
    withSpacing(
        styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
            justifyContent: 'flex-start',
            ...theme._CaptionSemibold,
            color: theme.ContentTertiary,
            borderTop: `1px solid ${theme.BorderPrimary}`,
            '&:first-child': {
                borderTop: 'none',
            },
        })),
        { px: 2, py: 1.5 }
    )
)

const SectionHeader = <TExtra, TValue>({ item }: { item: AutocompleteItem<TExtra, TValue> }) => {
    return <SectionHeaderContainer>{item.label}</SectionHeaderContainer>
}

export interface AutocompleteItem<T = any, TValue = string | number | undefined> {
    label: string
    value: TValue
    extra?: T
    children?: AutocompleteItem<T, TValue>[]
}

interface AutocompleteInputProps<TExtra = any, TValue = string | number | undefined> {
    options?: AutocompleteItem<TExtra, TValue>[]
    required?: boolean
    value?: any
    noMatchString?: string
    inputClassName?: string
    disableAutocomplete?: boolean
    searchable?: boolean
    containerStyle?: React.CSSProperties
    fixedPosition?: boolean
    dropdownWidth?: number
    dropDownMaxHeight?: number
    dropdownContainerStyle?: React.CSSProperties
    disabled?: boolean
    getOptionsAsync?: (query: string) => Promise<AutocompleteItem<TExtra, TValue>[]>
    renderItem?: (item: AutocompleteItem<TExtra, TValue>) => JSX.Element
    ButtonComponent?: (props: { open: boolean } & ButtonHTMLAttributes<HTMLButtonElement>) => JSX.Element
    onChange?: (item: AutocompleteItem<TExtra, TValue>) => void
}

function isEmpty(value: any): boolean {
    return value === '' || value === undefined || value === null
}

function flatten<TExtra, TValue>(items: AutocompleteItem<TExtra, TValue>[]): AutocompleteItem<TExtra, TValue>[] {
    return items.reduce(
        (flatList, item) => [...flatList, ...(item.children ? flatten(item.children) : [item])],
        [] as AutocompleteItem<TExtra, TValue>[]
    )
}

export const AutocompleteInput = <TExtra, TValue>({
    value,
    options,
    getOptionsAsync,
    onChange,
    fixedPosition,
    renderItem,
    noMatchString,
    onBlur,
    className,
    inputClassName,
    disableAutocomplete,
    searchable,
    containerStyle,
    dropdownWidth,
    dropDownMaxHeight,
    dropdownContainerStyle,
    ButtonComponent,
    placeholder,
    disabled,
    ...rest
}: Omit<HtmlHTMLAttributes<HTMLInputElement>, 'onChange'> & AutocompleteInputProps<TExtra, TValue>) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [filteredItems, setFilteredItems] = useState<(JSX.Element | null)[]>([])
    const [timeoutId, setTimeoutId] = useState()
    const [listboxId] = useState('listbox-' + uuid())
    const flattenedOptions = useMemo(() => flatten(options || []), [options])
    const selectedOption = useMemo(() => {
        return flattenedOptions.find((o) => o.value === value || (isEmpty(o.value) && isEmpty(value)))
    }, [flattenedOptions, value])
    const [search, setSearch] = useState('')
    const searchableInputRef = useRef<HTMLInputElement>(null)
    const searchableInputContainerRef = useRef<HTMLDivElement>(null)

    const containerRef = useRef<HTMLUListElement>(null)
    const elementRef = useRef<HTMLElement>(null)

    const openList = useCallback(() => {
        !disabled && setOpen(true)
    }, [disabled])

    const toggleList = useCallback(() => {
        !disabled && setOpen((open) => !open)
    }, [disabled])

    const closeList = useCallback(() => {
        setTimeout(() => {
            setOpen(false)
        }, 10)
    }, [])

    const selectItem = useCallback(
        (item) => {
            onChange && onChange(item)
            closeList()
            ;(elementRef.current as any)?.focus()
        },
        [closeList, onChange]
    )

    const onBlurCb = useCallback(
        (e) => {
            if (searchable && e.relatedTarget && e.relatedTarget === searchableInputRef.current) {
                return
            }
            if (
                !e.relatedTarget ||
                ((e.relatedTarget as HTMLElement).getAttribute('role') !== 'option' &&
                    !(e.relatedTarget as HTMLElement).closest('[role=option]'))
            ) {
                closeList()
                onBlur && onBlur(e)
            }
        },
        [closeList, onBlur, searchable]
    )

    const createListItem = useCallback(
        (item: AutocompleteItem<TExtra, TValue>, key: string | number) => {
            return item.children ? (
                <React.Fragment key={key}>
                    <SectionHeader item={item} />
                    {item.children.map((child, i) => createListItem(child, `${key}-${i}`))}
                </React.Fragment>
            ) : (
                <li
                    key={key}
                    role="option"
                    aria-selected={item.value === value}
                    tabIndex={-1}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault()
                            selectItem(item)
                        }
                    }}
                    onBlur={onBlurCb}
                    onClick={() => selectItem(item)}
                >
                    {renderItem ? renderItem(item) : item.label}
                </li>
            )
        },
        [onBlurCb, renderItem, selectItem, value]
    )

    const onInputChange = useCallback(
        (e: any) => {
            const newValue = e.currentTarget.value
            onChange && onChange({ label: newValue, value: undefined } as any)
            if (getOptionsAsync) {
                if (timeoutId) {
                    clearTimeout(timeoutId)
                }
                setTimeoutId(
                    setTimeout(async () => {
                        setLoading(true)
                        const items = await getOptionsAsync(newValue)
                        setFilteredItems(items.map((item, i) => createListItem(item, i)))
                        setLoading(false)
                    }, 300) as any
                )
            }
            openList()
        },
        [createListItem, getOptionsAsync, onChange, openList, timeoutId]
    )

    const filter = useCallback(
        (
            items: AutocompleteItem<TExtra, TValue>[],
            filterValue: any,
            disableAutocomplete?: boolean
        ): AutocompleteItem<TExtra, TValue>[] => {
            return items.filter((item, i) => {
                let show = disableAutocomplete || !filterValue
                if (!show && !item.children) {
                    show = insensitiveContains(item.label, '' + filterValue)
                }
                if (!show && item.children?.length) {
                    item.children = filter(item.children, filterValue, disableAutocomplete)
                    show = item.children?.length > 0
                }
                return show
            })
        },
        []
    )

    useEffect(() => {
        if (searchable) {
            if (!open && search) {
                setSearch('')
                return
            }

            const timeout = setTimeout(() => {
                setFilteredItems(filter(options!, search).map((option, i) => createListItem(option, i)))
            }, 300)
            return () => clearTimeout(timeout)
        }
    }, [createListItem, filter, open, options, search, searchable])

    useEffect(() => {
        if (options) {
            setFilteredItems(filter(options, value, disableAutocomplete).map((option, i) => createListItem(option, i)))
        }
    }, [createListItem, disableAutocomplete, filter, options, value])

    const { t } = useTranslation('admin')

    const navigate = useCallback(
        (e) => {
            const focusElementOrInput = (element: any) => {
                if (element === searchableInputContainerRef.current) {
                    searchableInputRef.current?.focus()
                } else {
                    element?.focus()
                }
            }

            const getTarget = (isArrowDown: boolean) => {
                const active = document.activeElement
                const container = containerRef.current

                if (!container?.contains(active) || !active) {
                    return isArrowDown ? container?.firstElementChild : container?.lastElementChild
                }
                if (active === searchableInputRef.current) {
                    return isArrowDown
                        ? searchableInputContainerRef.current?.nextElementSibling
                        : searchableInputContainerRef.current?.previousElementSibling ||
                              containerRef.current?.lastElementChild
                }

                const sibling = isArrowDown ? active?.nextElementSibling : active?.previousElementSibling

                return (
                    sibling ||
                    (isArrowDown ? containerRef.current?.firstElementChild : containerRef.current?.lastElementChild)
                )
            }

            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                const isArrowDown = e.key === 'ArrowDown'
                const target = getTarget(isArrowDown)
                focusElementOrInput(target)
                e.preventDefault()
            }

            if (e.key === 'Escape') {
                closeList()
                elementRef.current?.blur()
            }
        },
        [closeList]
    )

    const handleIconClick = useCallback(
        (e) => {
            if (open) {
                onBlurCb(e)
            } else {
                openList()
                elementRef.current?.focus()
            }
        },
        [onBlurCb, open, openList]
    )

    return (
        <AutocompleteContainer grow={1} className={className} onKeyDown={navigate} style={containerStyle}>
            {disableAutocomplete ? (
                ButtonComponent ? (
                    <span className="contents" ref={elementRef as any}>
                        <ButtonComponent
                            disabled={disabled}
                            role="combobox"
                            open={open}
                            tabIndex={0}
                            onBlur={onBlurCb}
                            onClick={toggleList}
                            aria-expanded={open}
                            aria-controls={listboxId}
                            aria-disabled={disabled}
                        />
                    </span>
                ) : (
                    <StyledInputlikeDiv
                        {...rest}
                        tabIndex={disabled ? -1 : 0}
                        onBlur={onBlurCb}
                        onClick={toggleList}
                        onKeyPress={(e) => {
                            if (e.key === ' ') {
                                e.preventDefault()
                                toggleList()
                            }
                        }}
                        className={classNames(inputClassName, open ? 'open' : '', disabled ? 'disabled' : '')}
                        style={{
                            ...rest.style,
                            paddingRight: '32px',
                        }}
                        ref={elementRef as any}
                        role="combobox"
                        aria-expanded={open}
                        aria-controls={listboxId}
                        aria-disabled={disabled}
                    >
                        {selectedOption ? selectedOption.label : <span className="subtle">{placeholder}</span>}
                    </StyledInputlikeDiv>
                )
            ) : (
                <StyledInput
                    {...rest}
                    disabled={disabled}
                    onFocus={openList}
                    onBlur={onBlurCb}
                    onClick={openList}
                    value={value}
                    onChange={onInputChange}
                    className={classNames(inputClassName, open ? 'open' : '')}
                    ref={elementRef as any}
                    role="combobox"
                    aria-expanded={open}
                    aria-controls={listboxId}
                    autoComplete={'off'}
                    placeholder={placeholder}
                    aria-disabled={disabled}
                />
            )}
            {ButtonComponent ? null : (
                <StyledFontAwesomeIcon
                    className={classNames(disabled ? 'disabled' : '')}
                    icon={open ? faChevronUp : faChevronDown}
                    onClick={handleIconClick}
                    onMouseDown={(e) => e.preventDefault()}
                />
            )}
            {(open || loading) && (
                <DropDownOptionList
                    id={listboxId}
                    onBlur={onBlurCb}
                    ref={containerRef}
                    role={'listbox'}
                    style={dropdownContainerStyle}
                    dropdownMaxHeight={dropDownMaxHeight}
                    dropdownWidth={dropdownWidth}
                    inputRef={elementRef}
                    fixedPosition={fixedPosition}
                >
                    {searchable ? (
                        <SearchableInputContainer ref={searchableInputContainerRef}>
                            <FontAwesomeIcon className="icon" icon={faSearch} />
                            <SearchableInput
                                disabled={disabled}
                                ref={searchableInputRef}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                                tabIndex={-1}
                                value={search}
                                aria-expanded={open}
                                aria-controls={listboxId}
                                autoComplete={'off'}
                                placeholder={t('Search')}
                                aria-disabled={disabled}
                            />
                        </SearchableInputContainer>
                    ) : null}
                    {loading ? (
                        <li>
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </li>
                    ) : filteredItems?.length ? (
                        filteredItems
                    ) : (
                        <li className="no-match">{noMatchString || t('No match')}</li>
                    )}
                </DropDownOptionList>
            )}
        </AutocompleteContainer>
    )
}
