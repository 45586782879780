import { FC, useCallback, useEffect, useState } from 'react'
import { useBusiness } from '../../../hooks/use-business'
import { PaymentMethodBanner } from './PaymentMethodBanner'
import { ModalBackground } from '../../ui-kit/admin/modal/ModalBackground'
import { ModalContent } from '../../ui-kit/admin/modal/ModalContent'
import { ModalContainer } from '../../ui-kit/admin/modal/ModalBody'
import { ModalHeader } from '../../ui-kit/admin/modal/ModalHeader'
import { ModalFooter } from '../../ui-kit/admin/modal/ModalFooter'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '../../ui-kit/comopnents/Icon'
import { Trans } from 'react-i18next'
import { Flex } from '../../helpers/Flex'
import { PrimaryButtonNavLink } from '../../ui-kit/button/primary'
import { TextButton } from '../../ui-kit/button/TextButton'
import { Business } from '../../../store/auth/types'
import moment from 'moment'

const PaymentMethodModal: FC<{ business: Business; onDismiss: () => void }> = ({ business, onDismiss }) => {
    return (
        <ModalBackground>
            <ModalContainer width={400}>
                <ModalHeader>
                    <Flex justifyContent="flex-start">
                        <Icon
                            className="mrs only-desktop"
                            icon={faExclamationTriangle}
                            variant={'ContentWarning'}
                            fontSize={16}
                            containerSize={2.5}
                        />
                        <h2>
                            <Trans ns="admin">Update your payment card details</Trans>
                        </h2>
                    </Flex>
                </ModalHeader>
                <ModalContent>
                    <p>
                        <Trans ns="admin">
                            To ensure uninterrupted service, please update your payment card details.
                        </Trans>
                    </p>
                </ModalContent>
                <ModalFooter>
                    <Flex
                        grow={1}
                        justifyContent="flex-end"
                        desktopDirection="row"
                        flexDirection="column-reverse"
                        alignItems="stretch"
                        desktopAlignItems="center"
                        gap={2}
                    >
                        <TextButton className="neutral" type="button" onClick={onDismiss}>
                            <Trans ns="admin">I'll do it later</Trans>
                        </TextButton>
                        <PrimaryButtonNavLink
                            to={'/admin/subscription#payment-method'}
                            type="button"
                            onClick={onDismiss}
                        >
                            <Trans ns="admin">Update now</Trans>
                        </PrimaryButtonNavLink>
                    </Flex>
                </ModalFooter>
            </ModalContainer>
        </ModalBackground>
    )
}

const SETTINGS_KEY = 'hide_payment_method_warning'
const PAYMENT_METHOD_SNOOZE_DATE_KEY = 'payment_method_snooze_date'
const SNOOZE_DAYS = 7

export const PaymentMethodWarning: FC = () => {
    const business = useBusiness()
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (business?.billingCycle === 'annual') {
            window.localStorage.removeItem(SETTINGS_KEY)
            const snoozeDate = localStorage.getItem(PAYMENT_METHOD_SNOOZE_DATE_KEY)

            if (!snoozeDate) {
                setShowModal(true)
                return
            }

            const date = moment(snoozeDate, moment.ISO_8601, true)
            setShowModal(!date.isValid() || moment().diff(date, 'days') >= SNOOZE_DAYS)
        } else {
            const hide = window.localStorage.getItem(SETTINGS_KEY)
            setShowModal(!hide)
        }
    }, [business?.billingCycle])

    const hasPaymentMethodBanner =
        (business?.subscriptionTier === 'pro' || business?.subscriptionTier === 'pro-trial') &&
        !business.hasPaymentMethod &&
        !business.isCancelPending &&
        !business.isLifetimePro &&
        business.nextInvoiceDate

    const onDismiss = useCallback(() => {
        setShowModal(false)
        if (business?.billingCycle === 'annual') {
            window.localStorage.setItem(PAYMENT_METHOD_SNOOZE_DATE_KEY, moment().toISOString())
        }
        window.localStorage.setItem(SETTINGS_KEY, '1')
    }, [business?.billingCycle])

    return hasPaymentMethodBanner ? (
        showModal ? (
            <PaymentMethodModal onDismiss={onDismiss} business={business} />
        ) : (
            <PaymentMethodBanner business={business} />
        )
    ) : null
}
