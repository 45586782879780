import { useMemo } from 'react'

export const usePhoneNumberPlaceholders = (countryCode: string) => {
    return useMemo(() => {
        switch (countryCode) {
            case 'HU':
                return '+36...'
            case 'RO':
                return '+40...'
            case 'DE':
                return '+49...'
            case 'AT':
                return '+43...'
            case 'GR':
                return '+30...'
            case 'RS':
                return '+381...'
            case 'HR':
                return '+385...'
            case 'BG':
                return '+359...'
            default:
                return '+1...'
        }
    }, [countryCode])
}
